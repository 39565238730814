import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Tab from 'components/common/KtTabs/Tab';
import Tabs from 'components/common/KtTabs/Tabs';
import CONFIG from 'config/configProps';
import { Grid } from 'semantic-ui-react';
import KTBillability from './KTBillability';
import { isEmpty, isEqual } from 'utils/common';
import LabelWithSkeleton from 'components/common/KtLabelWithSkeleton';
import Icon from 'components/common/Icons';
import KTDownloads from 'components/KTDownloads';
import ToolTipShowEdit from 'components/common/ToolTipShowEdit';
import { globalAdobeAnalytics } from 'config/analytics';
import { bindActionCreators } from 'redux';
import actions from 'redux/actions';

const getHeading = activeIndex => {
  let heading = '',
    keyName = '';
  switch (activeIndex) {
    case 0:
      heading = 'Billability by Business';
      keyName = 'billabilityByBusiness';
      break;
    case 1:
      heading = 'Billability by Team Member';
      keyName = 'billabilityByTeamMember';
      break;
    default:
      heading = '';
      keyName = '';
  }
  return { heading, keyName };
};

const KTBilling = props => {
  const { adobeData } = props;
  const history = useHistory();
  const optionIndex = history.location.state?.optionIndex;
  const [activeIndex, setActiveIndex] = useState(
    optionIndex ? optionIndex : props.selectedIndexFromStore
  );

  const [showTooltip, setShowToolTip] = useState(false);
  const currentPage = getHeading(activeIndex);
  useEffect(() => {
    if (!adobeData?.[currentPage.keyName]) {
      const isScriptLoaded = globalAdobeAnalytics(
        `KTDashboard-${currentPage.heading}`
      );
      isScriptLoaded && updateAdobeData();
    }
  }, [adobeData, activeIndex]);

  const updateAdobeData = () => {
    if (!adobeData?.[currentPage.keyName])
      props.updateAdobeDetails({ [currentPage.keyName]: true });
  };

  const closeToolTipBox = () => {
    setShowToolTip(false);
  };

  const setSelectedIndex = index => {
    history.push({
      state: { optionIndex: index },
    });
    setActiveIndex(index);
    props.updateSelectedIndex(index);
  };

  return (
    <Grid className="Kt_main_body">
      <Grid.Row className="Kt_main_body_heading">
        <Grid.Column className="Kt_main_body_heading_left" width={8}>
          <div className="heading_value">
            <div className="heading_value_left"> {currentPage.heading}</div>
            <div
              className="tooltip-with-icon homeTileToolTip"
              onClick={() => setShowToolTip(true)}
            >
              <Icon
                name="info-prototype"
                class="info-icon"
                alt="infoIcon"
                src="/img/info-tableau.svg"
              />
            </div>
          </div>
          <div className="timeperiod">
            {isEmpty(props.lastUpdatedDate) ? (
              <LabelWithSkeleton isLoading size="small" showLine />
            ) : (
              `Data as of ${props.lastUpdatedDate}`
            )}
          </div>
        </Grid.Column>
        <Grid.Column
          className="Kt_main_body_heading_right"
          width={2}
          floated="right"
        >
          <KTDownloads
            showDownloadModal={false}
            reportName={CONFIG.BILLABILITY_REPORT_NAMES[activeIndex]}
            url={CONFIG.BILLABILITY_DOWNLOAD_URL[activeIndex]}
            passAllValue={isEqual(activeIndex, 0)}
            pageUrl={`KTDashboard-${currentPage.heading}`}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Column className="Kt_main_body_tabs" width={16}>
        <Tabs className="global-tabs" activeTabIndex={activeIndex}>
          {CONFIG.BILLING_TABS.map((tab, index) => {
            return (
              <Tab
                key={tab}
                tabText={tab}
                linkClassName="custom-link"
                tabIndex={index}
                handleTabClick={index => setSelectedIndex(index)}
              >
                <KTBillability selectedIndex={activeIndex} history={history} />
              </Tab>
            );
          })}
        </Tabs>
      </Grid.Column>
      {showTooltip && (
        <ToolTipShowEdit
          showPopup={showTooltip}
          handlePopupClose={closeToolTipBox}
          popupSelectedText={getHeading()}
          popupBody={CONFIG.BILLABILITY_TOOLTIP_TEXT[activeIndex]}
          showEditIcon={false}
          hideEditInfo={true}
          popupBodyClassName="infoText"
        />
      )}
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    selectedIndexFromStore: store.filterDataPt.selectedIndex,
    lastUpdatedDate: store.filterDataPt.lastUpdatedDate,
    adobeData: store.adobeData.adobeAnalyticsData,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    updateSelectedIndex: bindActionCreators(
      actions.protoTypeActions.updateSelectedIndex,
      dispatch
    ),
    updateAdobeDetails: bindActionCreators(
      actions.adobeAnalyticsActions.updateAdobeDetails,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KTBilling);
