import { connect } from 'react-redux';
import KTFiltersSection from './KTFilterSection';
import { bindActionCreators } from 'redux';
import { selectors } from 'redux/reducers';
import actions from 'redux/actions';
import { API_CALL_IDENTIFIERS } from 'redux/constants';

/**
 * method to create PROPS for seed Data  by fetching them from Store, using selectors
 * @param {*} state Application State
 * @param {*} apiIdentifier API Identifier (State Identifier)
 */
function getPropsForSeedData(state, apiIdentifier) {
  return {
    isLoading: selectors.apiCallStatusSelectors.getApiCallStatus(
      state,
      apiIdentifier
    ),
    data: selectors.filterSectionSelectorsPt.getResults(state, apiIdentifier),
  };
}

/**
 * Method to  Format the State and pass them as Props to the component
 * @param {*} state  application State
 */
function mapStateToProps(state) {
  return {
    appliedFilters: selectors.filterSectionSelectorsPt.getAppliedFilters(state),
    practiceAreaList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_PRACTICE_AREA_LIST
    ),
    geoLocationList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_GEO_LOCATION_LIST
    ),
    memberList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_TEAM_MEMBER_LIST
    ),
    jobTitleList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_JOB_TITLE_LIST
    ),
    locationList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_LOCATION_LIST
    ),
    defaultUserFilters: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_DEFAULT_USER_FILTERS
    ),
    isFilterOpen: selectors.filterSectionSelectorsPt.getFilterState(state),
    userDetails: selectors.ktHomeSelectors.getResults(
      state,
      API_CALL_IDENTIFIERS.FETCH_USER_DETAILS
    ),
    selectedIndexFromStore: state.filterDataPt.selectedIndex,
    selectedIdMember: state.homePageData.selectedMemberId,
    selectedBusinessMemberId: state.homePageData.selectedBusinessMemberId,
    selectedUserByImpersonator: state.homePageData.selectedUserByImpersonator,
    isDataReset: state.homePageData.isResetData,
    paTopicSubTopics:
      selectors.filterSectionSelectorsPt.getPaTopicsSubtopics(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      fetchFiltersData: bindActionCreators(
        actions.protoTypeActions.fetchSeedDataFilters,
        dispatch
      ),
      updateIsFilterOpen: bindActionCreators(
        actions.protoTypeActions.updateIsFilterOpen,
        dispatch
      ),
      fetchUserData: bindActionCreators(
        actions.protoTypeActions.fetchMemberList,
        dispatch
      ),
      updateIsMemberFilterSelected: bindActionCreators(
        actions.protoTypeActions.updateIsMemberFilterSelected,
        dispatch
      ),
      resetData: bindActionCreators(
        actions.homepageActions.updateIsResetData,
        dispatch
      ),
      updateUserDetails: bindActionCreators(
        actions.homepageActions.updateUserDetails,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KTFiltersSection);
