import React, { Component } from 'react';
import './KTCheckbox.scss';

class KTCheckbox extends Component {
  handleChangeWithVal = () => {
    return this.props.handleChange(this.props.value, this.props.name);
  };
  render() {
    const { checkboxColor, handleChange, labelName, isReturn } = this.props;
    let checkboxStatus = this.props.checked;
    let checkboxColorName = checkboxColor;
    let checkboxColorStatusClassName =
      checkboxColorName !== '' && checkboxStatus ? 'checked' : 'unchecked';

    return (
      <div className="kt-m-checkbox">
        <input
          type="checkbox"
          className={`kt-checkbox ${checkboxColorName} ${checkboxColorStatusClassName}`}
          checked={checkboxStatus}
          onChange={isReturn ? this.handleChangeWithVal : handleChange}
          name={labelName}
        />
        <label className="checkbox-label">{labelName}</label>
      </div>
    );
  }
}

export default KTCheckbox;

KTCheckbox.defaultProps = {
  checkboxColor: '',
};
