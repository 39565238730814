import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getUserList } from 'redux/actions/selfServiceActions';
import Icon from 'components/common/icon/Icon';
import DataTable from 'components/common/DataTable';
import { getApiFailMessage, processUserList } from 'utils';
import CONFIG from 'config/configProps';
import { updateToastNotificationDetails } from 'redux/actions';
import './UserList.scss';

const UserList = props => {
  const [loading, setLoading] = useState(true);
  const { users, dispatch, reloadUserList, enableEditView } = props;

  useEffect(() => {
    dispatch(getUserList())
      .catch(() => {
        dispatch(updateToastNotificationDetails(getApiFailMessage()));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reloadUserList, dispatch]);

  const handleEditClick = userInfo => () => enableEditView(userInfo);

  const columnsConf = [
    ...CONFIG.USER_LIST_COLUMNS,
    {
      title: 'Hire Date',
      dataField: 'originalHireDate',
      renderColumn: { type: 'Date', format: 'MMM-dd, yyyy' },
    },
    {
      title: '',
      dataField: '',
      columnProps: {
        textAlign: 'center',
      },
      renderColumn: (data, rowData) => {
        const { id, employeeIdHRO } = rowData;
        return (
          <Icon
            name="edit"
            style={{ cursor: 'pointer' }}
            size="large"
            onClick={handleEditClick(rowData)}
            id={`edit-user-${id}-${employeeIdHRO}`}
          />
        );
      },
    },
  ];

  return (
    <DataTable
      id="user-list-table"
      className="user-list-table"
      loading={loading}
      dataList={processUserList(users)}
      columns={columnsConf}
    />
  );
};

const mapStateToProps = store => ({
  users: store.selfServiceData.userList,
});

export default connect(mapStateToProps)(UserList);
