import React from 'react';
import LabelWithSkeleton from 'components/common/KtLabelWithSkeleton';
import { isEqual, times } from 'utils/common';

const KTHomeLoader = props => {
  const count = props?.count || 10;
  return (
    <div className={props?.className}>
      {times(count, i => (
        <div className="data_table_body_row" key={i}>
          {isEqual(props.section, 'Timesheet') && (
            <>
              <div className="week">
                <LabelWithSkeleton isLoading size="medium" showLine />
              </div>
              <div className="hours">
                <LabelWithSkeleton isLoading size="small" showLine />
              </div>
              <div className="status">
                <LabelWithSkeleton isLoading size="medium" showLine />
              </div>
            </>
          )}
          {isEqual(props.section, 'Most Downloaded') && (
            <>
              <div className="downlaodTitle">
                <LabelWithSkeleton isLoading size="x-large" showLine />
              </div>
              <div className="downloadCount">
                <LabelWithSkeleton isLoading size="small" showLine />
              </div>
            </>
          )}
          {isEqual(props.section, 'Top Client') && (
            <>
              <div className="topClient">
                <LabelWithSkeleton isLoading size="x-large" showLine />
              </div>
              <div className="topClienthours">
                <LabelWithSkeleton isLoading size="small" showLine />
              </div>
              <div className="charges">
                <LabelWithSkeleton isLoading size="small" showLine />
              </div>
            </>
          )}
          {isEqual(props.section, 'Billability Trends') && (
            <div className="marginBottom30">
              <LabelWithSkeleton
                isLoading
                size="xxx-large"
                showLine
                height={40}
              />
            </div>
          )}
          {isEqual(props.section, 'Engagement Depth') && (
            <div className="marginTop30">
              <LabelWithSkeleton
                isLoading
                size="xxx-large"
                showLine
                height={10}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default KTHomeLoader;
