import * as types from './actionTypes';
import { actions as peopleActions } from './peopleActions';
import { bcFilterActions as seedDataActions } from './filterActions';
import { filterActions as protoTypeActions } from './filterActionsPt';
import { homepageActions } from './ktHomeActions';
import { adobeAnalyticsActions } from './adobeAnalyticsActions';

export function updateUserHrId(data) {
  return {
    type: types.UPDATE_USER_HRID,
    data,
  };
}

export function updateHeader3Date(data) {
  return {
    type: types.SET_HEADER3_DATE,
    data,
  };
}

export function updateGlobalDropdownValue(data) {
  return {
    type: types.SET_DROPDOWN_GLOBAL_HEADER_VALUE,
    data,
  };
}

export function updateMemberGlobalDropdownValue(data) {
  return {
    type: types.SET_DROPDOWN_MEMBER_GLOBAL_HEADER_VALUE,
    data,
  };
}

export function updateBackToListViewPage(data) {
  return {
    type: types.BACK_TO_LIST_VIEW_PAGE,
    data,
  };
}

export function updateToastNotificationDetails(data) {
  return {
    type: types.UPDATE_TOAST_NOTIFICATION_DETAILS,
    data,
  };
}

export function closeGrantModal(data) {
  return {
    type: types.CLOSE_GRANT_MODAL,
    data,
  };
}

export function updateShowPageContentStatus(data) {
  return {
    type: types.SHOW_PAGE_CONTENT,
    data,
  };
}

export function setAdminClick(data) {
  return {
    type: types.SET_ADMIN_CLICK,
    data,
  };
}

export function checkBrowser(data) {
  return {
    type: types.CHECK_IE_BROWSER,
    data,
  };
}

export function updateCollectionData(collectionData) {
  return { type: types.UPDATE_COLLECTION_DATA, collectionData };
}

export function updateDisplayName(displayName) {
  return { type: types.UPDATE_DISPLAY_NAME, displayName };
}

export function updateIconInformation(iconInformation) {
  return { type: types.UPDATE_ICON_INFORMATION, iconInformation };
}

export function fetchImpersonatorList(fetchList) {
  return {
    type: types.FETCH_IMPERSONATOR_LIST,
    fetchImpersonatorList: fetchList,
  };
}

export function updateImpersonatorMemberList(impersonatorList) {
  return { type: types.UPDATE_IMPERSONATOR_LIST, impersonatorList };
}

export function updatedSelectedMemberByImpersonator(memberDetails) {
  return { type: types.UPDATE_SELECTED_MEMBER_BY_IMPERSONATOR, memberDetails };
}

export function updateGeoRegionList(geoRegionList) {
  return { type: types.UPDATE_GEO_REGION_LIST, geoRegionList };
}

export function showTooltipPopup(tooltipData) {
  return { type: types.SHOW_TOOLTIP_POPUP, tooltipData };
}

export function updateIsMenuOpen(status) {
  return { type: types.UPDATE_IS_MENU_OPEN, status };
}

export function updateHeaderHeight(height) {
  return { type: types.SET_HEADER_HEIGHT, height };
}

export function updateSidebarPathName(pathName) {
  return { type: types.SET_SIDEBAR_PATHNAME, pathName };
}

const moreActions = {
  peopleActions,
  seedDataActions,
  protoTypeActions,
  homepageActions,
  adobeAnalyticsActions,
};

export default moreActions;
