export function isVerticalOption(optionStyle) {
  return optionStyle === 'VERTICAL';
}

export function isHorizontalOption(optionStyle) {
  return optionStyle === 'HORIZONTAL';
}

export const backwardSelectionChange = (
  checked,
  checkedOptions,
  _currentNode,
  parentNodes
) => {
  if (parentNodes) {
    const { node, parent } = parentNodes;

    const partialChecked = (node?.children || []).some(
      parentChildNode =>
        (checkedOptions[parentChildNode.code] &&
          checkedOptions[parentChildNode.code].checked) ||
        (checkedOptions[parentChildNode.code] &&
          checkedOptions[parentChildNode.code].partialChecked)
    );

    if (!checked) {
      checkedOptions[node.code] = {
        checked,
        partialChecked,
        id: node.id,
        type: node.type,
        label: node.label,
      };
      if (parent) {
        backwardSelectionChange(checked, checkedOptions, node, parent);
      }
    } else {
      const allParentNodeChildrensChecked = (node?.children || []).every(
        parentChildNode =>
          checkedOptions[parentChildNode.code] &&
          checkedOptions[parentChildNode.code].checked
      );

      checkedOptions[node?.code] = {
        checked: allParentNodeChildrensChecked,
        partialChecked,
        children:
          node?.children.length >= 1
            ? node.children.map(obj => obj.code)
            : undefined,
        node: {
          node,
          parent,
        },
        id: node?.id,
        type: node?.type,
      };
      if (allParentNodeChildrensChecked || partialChecked) {
        backwardSelectionChange(
          allParentNodeChildrensChecked,
          checkedOptions,
          node,
          parent
        );
      }
    }
  }
  return checkedOptions;
};

export const forwardSelectionChange = (
  checked,
  checkedOptions,
  option,
  dropdownOptions
) => {
  if (option === 'All') {
    dropdownOptions.map(el => {
      return (checkedOptions = getCheckedOption(checked, checkedOptions, el));
    });
  } else {
    checkedOptions = getCheckedOption(checked, checkedOptions, option);
  }
  return checkedOptions;
};

export const getCheckedOption = (checked, checkedOptions, option) => {
  const { id, children, type, code, label } = option;
  checkedOptions[code] = {
    checked,
    partialChecked: checked,
    type: type,
    id: id,
    label: label,
  };
  if (children) {
    children.forEach(child => {
      forwardSelectionChange(checked, checkedOptions, child, {
        type: type,
      });
    });
  }
  return checkedOptions;
};

export function getLengthOfTreeData(treeData) {
  let size = { size: 0 }; // object because it needs to be passed by reference
  return getSize(size, treeData).size;
}

function getSize(size, treeData) {
  // recursive function
  if (treeData.length === 0) {
    return size;
  }

  size.size += treeData.length;
  for (let i = 0; i < treeData.length; i++) {
    const data = treeData[i];
    if (data.children) getSize(size, data.children);
  }
  return size;
}

export const getLenghtOfSelectedValue = option => {
  let length = 0;
  Object.keys(option).forEach(value => {
    if (option[value].checked) {
      length++;
    }
  });
  return length;
};
