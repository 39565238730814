import React from 'react';
import KTPieChart from './KTPieChart';
import { isEmptyOrNull } from 'utils';
import { isLoading } from 'utils/common';
import KTHomeLoader from './KTHomeLoader';

const KTRms = props => {
  let pieChartRawData = props.data?.data?.results || {};
  return (
    <div className="home_page_main_split_row_ct_satisfaction main_conatiner">
      <div className="heading">
        <div className="heading_text">RMS CT SATISFACTION</div>
      </div>
      {isLoading(props.data) ? (
        <KTHomeLoader isLoading={1} section="Engagement Depth" count={6} />
      ) : isEmptyOrNull(pieChartRawData) ? (
        <div className="static-text">No data to display</div>
      ) : (
        <KTPieChart
          rawData={pieChartRawData}
          legends={['positive', 'neutral', 'negative']}
          colors={{
            positive: 'rgb(139, 201, 128)',
            neutral: 'rgb(224, 224, 224)',
            negative: 'rgb(245, 153, 157)',
          }}
          pieSizeOptions={{
            center: ['50%', '42%'],
          }}
          targetValue={pieChartRawData.totalCount || 100}
        />
      )}
    </div>
  );
};

export default KTRms;
