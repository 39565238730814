import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import CONFIG from 'config/configProps';
import './KTDateRangePicker.scss';
import { formatSelectedDate } from 'utils/common';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';

export default class KTDateRangePicker extends Component {
  static propTypes = {
    onDateChange: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      value: new Date(props.filterValue),
      inputValue: props.filterValue,
      startDate: props.startDate,
      endDate: props.endDate,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      value: new Date(nextProps.filterValue),
      inputValue: nextProps.filterValue,
      startDate: nextProps.startDate,
      endDate: nextProps.endDate,
    };
  }

  onDateSelectionChange = selectedDate => {
    if (!selectedDate) {
      this.setState({ value: '' });
      this.props.onDateChange('', '');
    } else {
      const formattedDate = formatSelectedDate(
        selectedDate,
        CONFIG.DATE_TIME_FORMAT.KT_SCORECARD_API
      );
      this.setState(
        {
          value: selectedDate,
          inputValue: formattedDate,
        },
        () => this.props.onDateChange(formattedDate, this.props.name)
      );
    }
  };

  render() {
    const { filterTitle, minDate, containerClass, maxDate, testId } =
      this.props;
    const { value, inputValue, startDate, endDate } = this.state;
    const minimumDate = minDate ? minDate : moment().subtract(2, 'year');
    const maximumDate = maxDate ? maxDate : new Date();
    return (
      <div
        className={`dateRangeContainer ${containerClass ? containerClass : ''}`}
        data-testid={testId}
      >
        <div className="title">{filterTitle}</div>
        <div className="filterContainer">{inputValue}</div>
        <div className="calendarContainer">
          <Calendar
            calendarType="ISO 8601"
            minDate={moment(minimumDate).toDate()}
            returnValue={'start'}
            selectRange={false}
            onChange={this.onDateSelectionChange}
            showNeighboringMonth
            value={value}
            view="month"
            maxDate={moment(maximumDate).toDate()}
            data-testid="calender-container"
            tileClassName={({ date }) => {
              const formattedDate = formatSelectedDate(
                date,
                CONFIG.DATE_TIME_FORMAT.KT_SCORECARD_API
              );
              if (
                startDate &&
                endDate &&
                formattedDate >= startDate &&
                formattedDate <= endDate
              ) {
                return 'highlight';
              }
            }}
          />
        </div>
      </div>
    );
  }
}
