import React, { useEffect, useState, Suspense } from 'react';
import { connect } from 'react-redux';
import CONFIG from 'config/configProps';
import KTBillabilityLoader from '../common/KTBillabilityLoader';

import {
  isEmptyOrNull,
  getPostData,
  isManagerUser,
  getPostDataForBusinessView,
  isLeaderRole,
  isAnalyst,
} from 'utils';
import KTBillabilityBusiness from './KTBillabilityBusiness';
import { fetchPost } from 'utils/KtCommonServices';
import { isEqual } from 'utils/common';
import Button from 'components/common/button';
import Icon from 'components/common/icon/Icon';

const legends = CONFIG.PROTOTYPE_BILLABILITY_COLORS;
const sortingOptions = CONFIG.PROTOTYPE_BILLABILITY_SORT_BY_OPTIONS;

const KTBillability = props => {
  const {
    selectedIndex,
    appliedFilter,
    memberList,
    userDetails,
    practiceArea,
    geoMarket,
    impersonatedUserDetails,
  } = props;
  const loggedInUserRole = !isEmptyOrNull(impersonatedUserDetails?.results)
    ? impersonatedUserDetails?.results
    : userDetails?.defaultKTRole;
  if (isAnalyst(loggedInUserRole)) {
    props.history.push(CONFIG.PROTOTYPE_URLS.HOME);
  }
  let defaultValue = {};
  defaultValue[`Case BillabilityDesc`] = true;

  const [selectedOption, setSelectedOption] = useState(sortingOptions[0]);
  const [showLoader, setShowLoader] = useState(true);
  const [apiData, setApiData] = useState();
  const [apiRequestData, setApiRequestData] = useState();

  useEffect(() => {
    setShowLoader(true);
    setApiData();
    setSelectedOption(sortingOptions[0]);
    getApiData();
  }, [selectedIndex, appliedFilter, impersonatedUserDetails]);

  const getApiData = () => {
    const isBusinessData = isEqual(selectedIndex, 0);
    let postData = getPostData(appliedFilter, memberList);
    if (isEqual(appliedFilter.selectedUserId, '00000')) {
      setShowLoader(false);
      setApiData({});
      return;
    }
    if (isEmptyOrNull(postData)) return;
    if (isBusinessData) {
      postData = { ...appliedFilter };
      if (isManagerUser(loggedInUserRole) || isLeaderRole(loggedInUserRole)) {
        if (
          isEmptyOrNull(appliedFilter.paIds) &&
          isEmptyOrNull(appliedFilter.geoRegion)
        ) {
          postData = getPostDataForBusinessView(
            practiceArea,
            geoMarket,
            postData,
            props.defaultUserFilters
          );
        }
      } else {
        postData['dateFilterType'] =
          !isEmptyOrNull(appliedFilter.dateFilterType) ||
          appliedFilter?.dateFilterType
            ? appliedFilter.dateFilterType
            : 'MONTHLY';
      }
      if (!props.isMemberFilterSelected) delete postData.selectedUserId;
    }
    if (isEqual(postData, apiRequestData)) return;
    const baseUrl = CONFIG.KT_BILLING_API_URL;
    let endpoint = '';
    if (isEqual(selectedIndex, 1)) {
      endpoint = CONFIG.PROTOTYPE_API_URLS.MEMBER_BILLABILITY;
    } else {
      endpoint = CONFIG.PROTOTYPE_API_URLS.BUSINESS_BILLABILITY;
    }
    fetchPost(endpoint, postData, baseUrl).then(response => {
      const apiResponse = response?.data;
      setApiRequestData(postData);
      setApiData(apiResponse);
      setShowLoader(false);
    });
  };

  const renderChartHeaders = () => {
    return (
      <div className="kt-billability-container__chart__headers">
        <div className="kt-billability-container__chart__headers__sortby">
          {isEqual(selectedOption, sortingOptions[0]) ? (
            <Button
              primary
              onClick={() => setSelectedOption(sortingOptions[1])}
              className="collpase"
            >
              <span>
                {sortingOptions[0]}
                <Icon name="angle down" />
              </span>
            </Button>
          ) : (
            <Button
              primary
              onClick={() => setSelectedOption(sortingOptions[0])}
              className="expand"
            >
              <span>
                {sortingOptions[1]}
                <Icon name="angle down" />
              </span>
            </Button>
          )}
        </div>
        {renderChartLegends()}
      </div>
    );
  };

  const renderChartLegends = () => {
    return (
      <div className="kt-billability-container__chart__headers__legends">
        <div>
          <div>
            {Object.entries(legends).map(([label, backgroundColor]) => {
              return (
                <span
                  key={label}
                  className="kt-billability-container__chart__headers__legends__item"
                >
                  <label
                    style={{ backgroundColor }}
                    className="kt-billability-container__chart__headers__legends__item__mark"
                  >
                    &nbsp;
                  </label>
                  {label}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderChartDataTable = () => {
    const propPack = {
      selectedOption: selectedOption,
      apiData: apiData,
      legends: CONFIG.PROTOTYPE_BILLABILITY_LEGENDS,
      isMemberView: isEqual(selectedIndex, 1),
    };
    return <KTBillabilityBusiness {...propPack} />;
  };

  const renderBillabilityChart = () => {
    let headerColumns = CONFIG.PROTOTYPE_BILLABILITY_COLUMNS;
    headerColumns = isEqual(selectedIndex, 1)
      ? headerColumns.memberBillability
      : headerColumns.businessBillability;

    return (
      <div className="chart__table">
        <div className="chart__table__header">
          {Object.keys(headerColumns).map((col, index) => {
            return (
              <div
                key={col}
                className={isEqual(index, 0) ? 'first_header' : ''}
              >
                {col}
              </div>
            );
          })}
          <div />
        </div>
        <div className="chart__table__body">
          {isEmptyOrNull(apiData?.allBillabilityMap || {}) ? (
            <div>No Record to display</div>
          ) : (
            <Suspense fallback={<KTBillabilityLoader />}>
              {renderChartDataTable()}
            </Suspense>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="kt-billability-container">
      <div className="kt-billability-container__chart">
        {renderChartHeaders()}
        {showLoader ? <KTBillabilityLoader /> : renderBillabilityChart()}
      </div>
    </div>
  );
};
const mapStateToProps = store => {
  return {
    appliedFilter: store.filterDataPt.filters,
    memberList: store.filterDataPt.fetchTeamMemberList?.results || [],
    userDetails: store.homePageData.fetchUserDetails?.results || {},
    practiceArea: store.filterDataPt.fetchPracticeAreaList?.results || [],
    geoMarket: store.filterDataPt.fetchGeoLocationList?.results || [],
    isMemberFilterSelected: store.filterDataPt.isMemberFilterSelected,
    impersonatedUserDetails: store.homePageData.fetchImpersonatedUserDetails,
    defaultUserFilters:
      store.filterDataPt.fetchDefaultUserFilters?.results || [],
  };
};
export default connect(mapStateToProps)(KTBillability);
