import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CONFIG from 'config/configProps';
import KTBillabilityLoader from '../common/KTBillabilityLoader';
import { isEmptyOrNull, getPostData, isAnalyst } from 'utils';
import { fetchPost } from 'utils/KtCommonServices';
import './index.scss';
import { isEqual, formatDate } from 'utils/common';
import { useHistory } from 'react-router-dom';

const KTTimeSheetTable = props => {
  const { appliedFilter, memberList, userDetails, impersonatedUserDetails } =
    props;
  const [showLoader, setShowLoader] = useState(true);
  const [apiData, setApiData] = useState();
  const [showTooltip, setShowToolTip] = useState({});
  const [apiRequestData, setApiRequestData] = useState();

  const history = useHistory();
  const loggedInUserRole = !isEmptyOrNull(impersonatedUserDetails?.results)
    ? impersonatedUserDetails?.results
    : userDetails?.defaultKTRole;
  if (isAnalyst(loggedInUserRole)) {
    history.push(CONFIG.PROTOTYPE_URLS.HOME);
  }

  useEffect(() => {
    setShowLoader(true);
    getApiData();
  }, [appliedFilter]);

  const getApiData = () => {
    const dataToPost = getPostData(appliedFilter, memberList);
    if (isEmptyOrNull(dataToPost)) return;
    if (isEqual(dataToPost, apiRequestData)) return;
    const baseUrl = CONFIG.KT_BILLING_API_URL;
    let endpoint = '';
    endpoint = CONFIG.PROTOTYPE_API_URLS.TIMESHEET;
    fetchPost(endpoint, dataToPost, baseUrl).then(response => {
      const apiResponse = response?.data;
      setApiRequestData(dataToPost);
      setApiData(apiResponse);
      setShowLoader(false);
    });
  };

  const renderBillabilityChart = () => {
    let headerColumns = isEmptyOrNull(apiData) ? [] : apiData.allWeeks;
    let employeesData =
      isEmptyOrNull(apiData) || isEmptyOrNull(apiData?.employees)
        ? {}
        : apiData.employees;
    let keys = Object.keys(headerColumns);
    return (
      <div className="timeSheet-table">
        <div className="timeSheet-table__inner">
          <table className="timeSheet-table__inner__main">
            <tr className="timeSheet-table__inner__main__header">
              <th className="header-1">Name</th>
              <th className="header-2">On-Time</th>
              {keys.map(key => {
                return (
                  <td className="heading" key={key}>
                    {formatDate(key, 'MMM-dd, yyyy')}
                  </td>
                );
              })}
            </tr>
            {Object.keys(employeesData).map(key => {
              const val = employeesData[key];
              return (
                <tr className="timeSheet-table__inner__main__body" key={key}>
                  <th className="header-1">{key}</th>
                  <th className="header-2">{`${val.onTimePercentage}%`}</th>
                  {keys.map((colunsKey, index) => {
                    if (!isEmptyOrNull(val.weeklyDetails)) {
                      const weeklyDetails = val.weeklyDetails.find(
                        e => e.weekPostedDate === colunsKey
                      );
                      if (!isEmptyOrNull(weeklyDetails)) {
                        const tooltipClassName = showTooltip[
                          `${key}_${weeklyDetails.weekPostedDate}_${index}_ToolTip`
                        ]
                          ? 'on'
                          : 'off';
                        return (
                          <td
                            className={`value ${
                              !isEqual(
                                weeklyDetails.hoursSubmitted,
                                weeklyDetails.theoCapHrs
                              ) && !isEqual(weeklyDetails.hoursSubmitted, '0')
                                ? 'highlighted'
                                : ''
                            } ${
                              isEqual(weeklyDetails.hoursSubmitted, '0')
                                ? 'late'
                                : ''
                            }`}
                          >
                            <span
                              className="tooltip-with-icon periodTooltip desc"
                              onMouseEnter={() => {
                                const newState = {};
                                newState[
                                  `${key}_${weeklyDetails.weekPostedDate}_${index}_ToolTip`
                                ] = true;
                                setShowToolTip({ ...newState });
                              }}
                              onMouseLeave={() => {
                                const newState = {};
                                newState[
                                  `${key}_${weeklyDetails.weekPostedDate}_${index}_ToolTip`
                                ] = false;
                                setShowToolTip({ ...newState });
                              }}
                            >
                              <div>
                                {isEqual(weeklyDetails.hoursSubmitted, '0')
                                  ? 'Late'
                                  : weeklyDetails.hoursSubmitted}
                              </div>
                              <div id="tooltip" className={tooltipClassName}>
                                <div className="tooltip-inner">
                                  <div className="inner-row">
                                    <div className="inner-row_heading">
                                      Week Posted Date:
                                    </div>
                                    <div className="inner-row_val">
                                      {weeklyDetails.weekPostedDate}
                                    </div>
                                  </div>
                                  <div className="inner-row">
                                    <div className="inner-row_heading">
                                      Hours Submitted:
                                    </div>
                                    <div className="inner-row_val">
                                      {weeklyDetails.hoursSubmitted}
                                    </div>
                                  </div>
                                  <div className="inner-row">
                                    <div className="inner-row_heading">
                                      Theo Cap Hrs:
                                    </div>
                                    <div className="inner-row_val">
                                      {weeklyDetails.theoCapHrs}
                                    </div>
                                  </div>
                                  <div className="inner-row">
                                    <div className="inner-row_heading">
                                      Status:
                                    </div>
                                    <div className="inner-row_val">
                                      {weeklyDetails.status}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </td>
                        );
                      } else {
                        return <td className="grey" />;
                      }
                    }
                  })}
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="kt-billability-container">
      <div className="kt-billability-container__chart">
        {showLoader ? <KTBillabilityLoader /> : renderBillabilityChart()}
      </div>
    </div>
  );
};

const mapStateToProps = store => {
  return {
    appliedFilter: store.filterDataPt.filters,
    memberList: store.filterDataPt.fetchTeamMemberList?.results || [],
    impersonatedUserDetails: store.homePageData.fetchImpersonatedUserDetails,
    userDetails: store.homePageData.fetchUserDetails?.results || {},
  };
};
export default connect(mapStateToProps)(KTTimeSheetTable);
