import React from 'react';
import { Table } from 'semantic-ui-react';
import { isArray, isEmpty, isEqual, numberWithCommas } from 'utils/common';

const KTDetailedView = props => {
  const { apiData } = props;
  const calculateLengthOfObject = (obj, length) => {
    const hasProjectData = isArray(obj);
    let lengthOfObject = Object.keys(obj).length + length;
    !hasProjectData &&
      Object.entries(obj).map(subObj => {
        if (isEqual(subObj[0], 'EmployeeTotal')) return lengthOfObject;
        return (lengthOfObject = calculateLengthOfObject(
          subObj[1],
          lengthOfObject
        ));
      });
    return lengthOfObject;
  };

  const renderChartDataTable = () => {
    let sortedUsers = apiData?.employeeMap || {};
    if (sortedUsers.length === 0) {
      return undefined;
    }
    const userList = Object.entries(sortedUsers);
    return userList.map(user => {
      return renderTableColumn(user);
    });
  };

  const renderTableColumn = (data, isMainTotal) => {
    const hasProjectData = isArray(data[1]);
    let projectDetails = hasProjectData ? data[1] : {};
    const isCategory = data[1]?.CategoryTotal;
    const isEmployeeTotal = isEqual(data[0], 'EmployeeTotal');
    const isTotal = isEqual(data[0], 'Total');
    const isFinalTotal = isEmployeeTotal || isTotal || isMainTotal;
    const isCatTotal =
      isEqual(data[0], 'CategoryTotal') || isEqual(data[0], 'CategoryTotals');
    return (
      <>
        <Table.Row
          key={data[0]}
          className={`kt-billability-container__chart__table__body_main ${
            isCatTotal ? 'grey' : ''
          }`}
        >
          {isFinalTotal ? null : (
            <Table.Cell
              className="employee_name"
              rowSpan={calculateLengthOfObject(data[1], isCategory ? 0 : 1)}
            >
              {isCatTotal ? '' : data[0]}
            </Table.Cell>
          )}
        </Table.Row>

        {!isEmpty(projectDetails)
          ? projectDetails.map(el => {
              return (
                <Table.Row
                  key={isMainTotal ? 'mainTotal' : el.projectId}
                  className={isCatTotal || isFinalTotal ? 'grey' : ''}
                >
                  <Table.Cell className="digit_val">
                    {isMainTotal
                      ? 'Grand Total'
                      : isCatTotal || isTotal
                      ? 'Total'
                      : el.projectId}
                  </Table.Cell>
                  {isCatTotal && <Table.Cell className="digit_val" />}
                  {isTotal && <Table.Cell className="digit_val" />}
                  {isTotal && <Table.Cell className="digit_val" />}
                  {isTotal && <Table.Cell className="digit_val" />}
                  {isTotal && <Table.Cell className="digit_val" />}
                  <Table.Cell className="digit_val">
                    {numberWithCommas(isMainTotal ? el.Charges : el.charges)}
                  </Table.Cell>
                  <Table.Cell className="digit_val">
                    {isMainTotal ? el.Hours : el.hours}
                  </Table.Cell>
                  <Table.Cell className="digit_val hours">
                    {isMainTotal ? el.Non_Conv_Hours : el.nonConvHours}
                  </Table.Cell>
                  <Table.Cell className="digit_val theo">
                    {numberWithCommas(
                      isMainTotal ? el.Theo_Cap_Chgs : el.theoCapChgs
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })
          : null}
        {!hasProjectData &&
          Object.entries(data[1]).map(obj => {
            return renderTableColumn(obj);
          })}
      </>
    );
  };

  return (
    <>
      {renderChartDataTable()}
      {renderTableColumn(apiData?.EmployeeTotal, true)}
    </>
  );
};

export default KTDetailedView;
