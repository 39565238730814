import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Icon from 'components/common/icon/Icon';
import './KTTimePeriod.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { isEqual, formatSelectedDate } from 'utils/common';
import { selectedDates } from 'utils';
import CONFIG from 'config/configProps';
import KTDateRangePicker from 'components/common/KTDateRangePicker';
import { onFiltersDropDownOpen, onFiltersInteractions } from 'config/analytics';

const KTTimePeriod = props => {
  const {
    onSelectionChange,
    isAnalystViewCal,
    selectedStartDate,
    selectedEndDate,
    selectedDateFilterType,
    selectedTimePeriodType,
  } = props;
  const { startDate, endDate } = selectedDates(
    new Date(),
    '',
    'Last 12 Months',
    true
  );
  let selectedMonthWeek = selectedTimePeriodType
    ? getMonthWeekValue(selectedTimePeriodType)
    : { month: 12, week: '' };
  const startDateSelected = selectedStartDate ? selectedStartDate : startDate;
  const endDateSelected = selectedEndDate ? selectedEndDate : endDate;
  const refContainer = useRef();
  const [isDropdownOpen, toggleDropdown] = useState(false);
  const [showCalender, setShowCalender] = useState(false);
  const [monthVal, updateMonthVal] = useState(selectedMonthWeek.month);
  const [weekVal, updateWeekVal] = useState(selectedMonthWeek.week);
  const [activeItem, setActiveItem] = useState(
    selectedDateFilterType ? selectedDateFilterType : 'MONTHLY'
  );
  const [timePeriodVal, setTimePeriodVal] = useState(
    selectedTimePeriodType ? selectedTimePeriodType : 'Last 12 Months'
  );
  const [startDateVal, setStartDate] = useState(startDateSelected);
  const [endDateVal, setEndDate] = useState(endDateSelected);
  const [selectedDateRange, setSelectedDateRange] = useState('');
  const [isDateCustom, setIsDateCustom] = useState(false);
  const [calendarStartDate, setCalendarStartDate] = useState(startDateSelected);
  const [calendarEndDate, setCalendarEndDate] = useState(endDateSelected);
  function openDropdown() {
    toggleDropdown(!isDropdownOpen);
  }

  const getActiveItem = item => {
    return isEqual(item, 'TTM') ? 'MONTHLY' : item;
  };

  function getMonthWeekValue(val) {
    let month,
      week = '';
    let value = val.split(' ');
    if (val.includes('Months')) {
      month = value[1];
    } else if (val.includes('Weeks')) {
      week = value[1];
    }
    return { month, week };
  }

  useEffect(() => {
    onFiltersDropDownOpen(`Date Filter Opened`);
  }, []);

  useEffect(() => {
    !isAnalystViewCal &&
      onSelectionChange(startDate, endDate, getActiveItem(activeItem));
    const handleClickOutside = event => {
      if (
        refContainer.current &&
        !refContainer.current.contains(event.target)
      ) {
        toggleDropdown(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onSelectionChange]);

  const onInputChange = (value, item) => {
    if (isEqual(item, 'Months')) {
      updateMonthVal(value);
      updateWeekVal('');
    } else {
      updateWeekVal(value);
      updateMonthVal('');
    }
    setTimePeriodVal(`Last ${value} ${item}`);
  };

  const onClickHandle = item => {
    setActiveItem(item);
    setShowCalender(isEqual(item, 'CUSTOM') && !showCalender);
    updateWeekVal('');
    updateMonthVal('');
    setTimePeriodVal(item);
    setIsDateCustom(false);
  };

  const applyFilter = () => {
    if (!isDateCustom) {
      let startDate,
        endDate = '';
      if (selectedDateRange) {
        const dateArray = selectedDateRange.split('/');
        startDate = dateArray[0];
        endDate = dateArray[1];
      } else {
        const dateVal = selectedDates(new Date(), '', timePeriodVal, true);
        startDate = dateVal.startDate;
        endDate = dateVal.endDate;
      }
      setStartDate(startDate);
      setEndDate(endDate);
      onSelectionChange(
        startDate,
        endDate,
        getActiveItem(activeItem),
        timePeriodVal,
        true
      );
    } else {
      setStartDate(calendarStartDate);
      setEndDate(calendarEndDate);
      onSelectionChange(
        calendarStartDate,
        calendarEndDate,
        getActiveItem(activeItem),
        timePeriodVal,
        true
      );
    }
    onFiltersInteractions('Date', `Date Filter applied`);
    setSelectedDateRange('');
    toggleDropdown(false);
    setShowCalender(false);
  };

  const listItem = (item, value, activeValue) => {
    return (
      <div
        className={`date-calender-container__left__list-item ${
          isEqual(activeItem, activeValue) ? 'active' : ''
        }`}
        onClick={() => onClickHandle(activeValue)}
      >
        <span>Last</span>
        <span>
          <input
            type="text"
            onChange={e => onInputChange(e.target.value, item)}
            readOnly={activeItem !== activeValue}
            value={value}
            name={item}
          />
        </span>
        <span>{item}</span>
      </div>
    );
  };

  const onLoadChange = (date, name) => {
    if (isEqual(name, 'startDate')) {
      setCalendarStartDate(date);
    } else {
      setCalendarEndDate(date);
    }
    toggleDropdown(true);
    setIsDateCustom(true);
  };

  const timeFormat = CONFIG.DATE_TIME_FORMAT_DISPLAY;
  return (
    <div
      className={`dropdownWith-btn ${props.disabled ? 'disabled' : ''} 
    ${props.className ? props.className : ''}`}
      ref={refContainer}
    >
      <div className="dropdown-selected-option" onClick={openDropdown}>
        {props.showDateHeading && (
          <img
            src="img/calendarNew.png"
            alt="calendar-icon"
            className="calendar-icon"
          />
        )}
        <span>{`${props.showDateHeading ? 'Date: ' : ''}${formatSelectedDate(
          startDateVal,
          timeFormat[activeItem]
        )} - ${formatSelectedDate(endDateVal, timeFormat[activeItem])}`}</span>
        <Icon name={`angle ${isDropdownOpen ? 'up' : 'down'}`} />
      </div>
      {isDropdownOpen && (
        <div
          className={`date-calender-container ${
            props.dateContainerclassName ? props.dateContainerclassName : ''
          }`}
        >
          <div className="date-calender-container__left">
            {listItem('Months', monthVal, 'MONTHLY')}
            {listItem('Weeks', weekVal, 'WEEKLY')}
            {CONFIG.PROTOTYPE_TIME_PERIOD_OPTIONS.map(item => {
              return (
                <div
                  className={`date-calender-container__left__list-item padding-l05 ${
                    isEqual(activeItem, item) ? 'active' : ''
                  }`}
                  onClick={() => onClickHandle(item)}
                >
                  {isEqual(item, 'CUSTOM') ? 'Custom' : item}
                </div>
              );
            })}
            {!showCalender && !isEqual(activeItem, 'CUSTOM') && (
              <div className="button-footer">
                <div className="apply" onClick={() => applyFilter()}>
                  Apply
                </div>
              </div>
            )}
          </div>
          {showCalender && (
            <div className="custom-calender">
              <div className="enabled">
                <KTDateRangePicker
                  onDateChange={onLoadChange}
                  filterValue={calendarStartDate}
                  filterTitle="From:"
                  name="startDate"
                  startDate={calendarStartDate}
                  endDate={calendarEndDate}
                />
                <KTDateRangePicker
                  onDateChange={onLoadChange}
                  filterValue={calendarEndDate}
                  filterTitle="To:"
                  name="endDate"
                  startDate={calendarStartDate}
                  endDate={calendarEndDate}
                />
              </div>
              <div className="button-footer">
                <div className="apply" onClick={() => applyFilter()}>
                  Apply
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = function (reduxStore) {
  return {
    timePeriodRange: reduxStore.headerFilterData.timePeriodRange,
  };
};
export default connect(mapStateToProps)(KTTimePeriod);
