import React, { useState } from 'react';
import CONFIG from 'config/configProps';
import { Grid } from 'semantic-ui-react';
import TreeSelect from 'components/KTVerticalDd';
import Icon from 'components/common/icon/Icon';
import { Accordion } from 'react-bootstrap';
import KTTimePeriod from 'components/KTTimePeriod';
import DropDownFilter from 'components/common/AdminFilter/KtDdFilters';
import {
  dropdownFormat,
  getDefaultSelectedPa,
  isBusinessView,
  isEmptyOrNull,
  isMemberView,
} from 'utils';
import { cloneDeep, isEmpty, isEqual } from 'utils/common';
import './index.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getFilters } from './KTFilterShowHide';
import { getWeekPostedDates } from 'utils/weekPostedDates';
import { useSelector } from 'react-redux';

const KTFilters = ({
  defaultValue,
  practiceArea,
  geoLocationList,
  jobTitle,
  location,
  members,
  onSelectionChange,
  onDateSelectionChange,
  onTreeSelectApply,
  isFilterOpen,
  // updateFilterState,
  isHomePage,
  userDetails,
  selectedIndex,
  selectedBusinessMemberId,
  selectedMemberId,
  defaultUserFilters,
  paTopicSubTopics,
}) => {
  const impersonatedUserRole = useSelector(
    state => state.homePageData?.fetchImpersonatedUserDetails?.results
  );
  /** Define Intial values */
  const FILTERS = CONFIG.PROTOTYPE_FILTERS;
  const userRole = !isEmptyOrNull(impersonatedUserRole)
    ? impersonatedUserRole
    : userDetails.results.defaultKTRole;
  const selectedTab = isHomePage
    ? CONFIG.PAGE_TABS[userRole][selectedIndex]
    : '';
  const isMemberViewOpen = isHomePage ? isMemberView(selectedTab) : false;
  const isBusinessViewOpen = isHomePage ? isBusinessView(selectedTab) : false;
  const history = useHistory();
  const weekPostedData = getWeekPostedDates(
    defaultValue.startDate,
    defaultValue.endDate
  );
  /**initial states */
  const [isDateOpen, setIsDateOpen] = useState(true);
  const [isMemberFilterOpen, setIsMemberFilterOpen] = useState(true);
  const [isBusinessFilterOpen, setIsBusinessFilterOpen] = useState(true);
  const [filterState, setFilterState] = useState({});
  const practiceAreaCopy = cloneDeep(practiceArea.data);
  const locationCopy = cloneDeep(location.data);
  const memberList = members.data;
  const pageName = history.location.pathname;
  const isBillRatePageOpen =
    isEqual(pageName, CONFIG.PROTOTYPE_URLS.CHARGES_HOURS) &&
    isEqual(history.location.state?.optionIndex, 1);
  const filtersToShow = getFilters(userRole, pageName, selectedIndex);
  const weekPostedDataCopy = cloneDeep(weekPostedData);

  /** function to render top date filter */
  const renderDateContainer = () => {
    return (
      <Accordion key="0" defaultActiveKey="0">
        {renderAccordionToggle(setIsDateOpen, isDateOpen, 'DATE', '0')}
        <Accordion.Collapse eventKey="0">
          <ul>
            <li>
              <KTTimePeriod onSelectionChange={onDateChange} />
            </li>
            {renderDdFilter(
              FILTERS.WEEK_POSTED_DATE,
              weekPostedDataCopy,
              false,
              true
            )}
          </ul>
        </Accordion.Collapse>
      </Accordion>
    );
  };

  /** function to render accordian filter */
  const renderAccordionToggle = (stateFunction, stateName, heading, index) => {
    return (
      <Accordion.Toggle
        eventKey={index}
        className="accordion_main"
        onClick={() => stateFunction(!stateName)}
      >
        <div className="text">{heading}</div>
        {stateName ? (
          <div className="minus">-</div>
        ) : (
          <div className="plus">+</div>
        )}
      </Accordion.Toggle>
    );
  };

  /** function to render team member filter */
  const renderTeamMemberFilters = () => {
    const showSearch = true;
    const showSelectedOnTop = true;
    return (
      <Accordion key="1" defaultActiveKey="1">
        {renderAccordionToggle(
          setIsMemberFilterOpen,
          isMemberFilterOpen,
          'TEAM MEMBER',
          '1'
        )}
        <Accordion.Collapse eventKey="1">
          <ul>
            {filtersToShow?.name &&
              renderDdFilter(
                FILTERS.NAME,
                getDataValue(memberList, FILTERS.NAME.field),
                showSearch,
                false,
                showSelectedOnTop
              )}
            {filtersToShow?.jobTitle &&
              renderDdFilter(
                FILTERS.JOB_TITLE,
                getDataValue(jobTitle.data),
                showSearch,
                false,
                showSelectedOnTop
              )}
            {filtersToShow?.location &&
              renderDdFilter(FILTERS.LOCATION, locationCopy, false, true)}
            {filtersToShow?.status &&
              renderDdFilter(FILTERS.STATUS, getDataValue(CONFIG.STATUS))}
          </ul>
        </Accordion.Collapse>
      </Accordion>
    );
  };

  /** function to render business filter */
  const renderBusinessFilters = () => {
    return (
      <Accordion key="2" defaultActiveKey="2">
        {renderAccordionToggle(
          setIsBusinessFilterOpen,
          isBusinessFilterOpen,
          'BUSINESS',
          '2'
        )}
        <Accordion.Collapse eventKey="2">
          <ul>
            {filtersToShow?.practiceArea
              ? renderDdFilter(
                  FILTERS.PRACTICE_AREA,
                  practiceAreaCopy,
                  false,
                  true
                )
              : null}

            {!isEmptyOrNull(geoLocationList.data) &&
              filtersToShow?.geoBusiness &&
              renderDdFilter(
                FILTERS.GEO_BUSINESS,
                getDataValue(geoLocationList.data)
              )}
            {filtersToShow?.managementModel &&
              renderDdFilter(
                FILTERS.MANAGEMENT_MODEL,
                getDataValue(CONFIG.MANAGEMENT_MODEL)
              )}
            {filtersToShow?.path &&
              renderDdFilter(FILTERS.PATH, CONFIG.FILTERS_PATH)}
          </ul>
        </Accordion.Collapse>
      </Accordion>
    );
  };

  /** function to get default text of filter */
  const getSelectedFilterText = (filter, selectedValues) => {
    let selectedText = filter.text;
    let length = filterState[`${filter.field}Length`];
    if (
      !(`${filter.field}Selected` in filterState) &&
      isEqual(filter.field, FILTERS.GEO_BUSINESS.field)
    ) {
      length = !isEmptyOrNull(selectedValues) ? selectedValues.length : 0;
    }
    if (
      !(`${filter.field}Selected` in filterState) &&
      isEqual(filter.field, FILTERS.PRACTICE_AREA.field)
    ) {
      length = !isEmptyOrNull(selectedValues)
        ? Object.keys(selectedValues).length
        : 0;
    }
    return { selectedText, filterLength: length };
  };

  const getSelectedValues = (selectedValue, field) => {
    let selectedValues = [];
    if (isEqual(field, FILTERS.NAME.field) && isEmpty(selectedValue)) {
      if (isMemberViewOpen) selectedValues = selectedMemberId;
      else if (isBusinessViewOpen && !isEmptyOrNull(selectedBusinessMemberId))
        selectedValues = selectedBusinessMemberId;
    } else if (
      !(`${field}Selected` in filterState) &&
      isEqual(field, FILTERS.GEO_BUSINESS.field)
    ) {
      selectedValues = defaultUserFilters?.data?.geoRegion;
    } else if (
      !(`${field}Selected` in filterState) &&
      isEqual(field, FILTERS.PRACTICE_AREA.field)
    ) {
      selectedValues = getDefaultSelectedPa(
        defaultUserFilters?.data,
        paTopicSubTopics
      );
    } else selectedValues = selectedValue;
    return selectedValues;
  };

  /** function to render dropdown single select/multiselct, tree structure filter */
  const renderDdFilter = (
    filter,
    dataList,
    showSearch,
    isTreeSelectFilter,
    showSelectedOnTop
  ) => {
    const field = filter.field;
    const filterStates = filterState[field];
    let selectedValues = isEmptyOrNull(dataList)
      ? []
      : `${field}Selected` in filterState
      ? filterState[`${field}Selected`]
      : defaultValue[field];
    selectedValues = getSelectedValues(selectedValues, field);
    const { selectedText, filterLength } = getSelectedFilterText(
      filter,
      selectedValues
    );
    const isMemberListEmpty =
      isEqual(field, FILTERS.NAME.field) &&
      !isEmptyOrNull(dataList) &&
      dataList.length === 1 &&
      dataList[0].value === '00000';
    const { paAll, topicSectorAll, subSectorAll, allWeekPostedDates } =
      defaultValue;
    return (
      <li key={filter.text}>
        <div
          className="filter-heading"
          onClick={() => {
            const newState = { ...filterState };
            newState[field] = !newState[field];
            setFilterState({ ...newState });
          }}
        >
          <div className="filter-name">
            <div className="text">{selectedText}</div>
            <Icon name={`angle ${filterStates ? 'up' : 'down'}`} />
          </div>
          {filterLength > 0 && !isMemberViewOpen && (
            <div className="selected-count">{filterLength}</div>
          )}
        </div>

        {filterStates &&
          (isEmptyOrNull(dataList) || isMemberListEmpty ? (
            <div>No Record</div>
          ) : isTreeSelectFilter ? (
            <TreeSelect
              filterName={field}
              dropdownOptions={
                isEqual(field, FILTERS.WEEK_POSTED_DATE.field)
                  ? dataList.reverse()
                  : dataList
              }
              selectedIds={selectedValues}
              applyFilter={onFilterChange}
              clickedValues={
                isEmptyOrNull(selectedValues)
                  ? {}
                  : { paAll, topicSectorAll, subSectorAll, allWeekPostedDates }
              }
              filterText={filter.text}
              userRole={userRole}
              defaultUserFilters={defaultUserFilters?.data}
            />
          ) : (
            <DropDownFilter
              dataList={dataList}
              onChange={onFilterChange}
              selectedList={selectedValues}
              clearFilter={onFilterChange}
              stateField={field}
              isAllSelected={isEqual(
                dataList?.length || 0,
                selectedValues?.length || 0
              )}
              showSearch={showSearch}
              placeHolder={'Search Name'}
              searchPosition={'Right'}
              singleSelect={isMemberViewOpen}
              hideButtonFooter={isMemberViewOpen}
              showSelectedOnTop={showSelectedOnTop}
              filterName={filter.text}
            />
          ))}
      </li>
    );
  };

  /** function to format filter list data in single format */
  const getDataValue = (data, field) => {
    if (isEmptyOrNull(data)) return;
    switch (field) {
      case FILTERS.NAME.field:
        return data?.map(({ employeeIdHRO, employeeName }) =>
          dropdownFormat('' + employeeIdHRO, employeeName)
        );
      default:
        return data?.map(el => dropdownFormat('' + el, el));
    }
  };

  /** action to perform on change of filter */
  const onFilterChange = (
    dropdownIdentifier,
    selectedValue,
    length,
    selectedName,
    isTreeChange,
    treeSelectedValues
  ) => {
    let newState = { ...filterState };
    if (!isEqual(dropdownIdentifier, FILTERS.NAME.field)) {
      newState[FILTERS.NAME.field] = false;
      newState[`${FILTERS.NAME.field}Length`] = 0;
      newState[`${FILTERS.NAME.field}Selected`] = [];
    }
    switch (dropdownIdentifier) {
      case FILTERS.PATH.field:
      case FILTERS.MANAGEMENT_MODEL.field:
      case FILTERS.GEO_BUSINESS.field:
      case FILTERS.PRACTICE_AREA.field:
        newState[`${FILTERS.LOCATION.field}Length`] = 0;
        newState[`${FILTERS.JOB_TITLE.field}Length`] = 0;
        newState[`${FILTERS.LOCATION.field}Selected`] = [];
        newState[`${FILTERS.JOB_TITLE.field}Selected`] = [];
        newState[FILTERS.LOCATION.field] = false;
        newState[FILTERS.JOB_TITLE.field] = false;
        break;
      case FILTERS.JOB_TITLE.field:
        newState[`${FILTERS.LOCATION.field}Length`] = 0;
        newState[`${FILTERS.LOCATION.field}Selected`] = [];
        newState[FILTERS.LOCATION.field] = false;
        break;
      case FILTERS.LOCATION.field:
      case FILTERS.WEEK_POSTED_DATE.field:
        newState[`${FILTERS.JOB_TITLE.field}Length`] = 0;
        newState[`${FILTERS.JOB_TITLE.field}Selected`] = [];
        newState[FILTERS.JOB_TITLE.field] = false;
        break;
      default:
        newState = { ...filterState };
    }
    newState[dropdownIdentifier] = false;
    newState[`${dropdownIdentifier}Length`] = length;
    newState[`${dropdownIdentifier}Selected`] = isTreeChange
      ? treeSelectedValues
      : selectedValue;
    newState['selectedName'] = selectedName;
    setFilterState({ ...newState });
    let passPA = false,
      passGeo = false;
    if (
      !isEqual(dropdownIdentifier, FILTERS.PRACTICE_AREA.field) &&
      !(FILTERS.PRACTICE_AREA.field in filterState)
    )
      passPA = true;
    if (
      !isEqual(dropdownIdentifier, FILTERS.GEO_BUSINESS.field) &&
      !(FILTERS.GEO_BUSINESS.field in filterState)
    )
      passGeo = true;
    if (isTreeChange)
      onTreeSelectApply(dropdownIdentifier, selectedValue, passPA, passGeo);
    else onSelectionChange(dropdownIdentifier, selectedValue, passPA, passGeo);
  };

  const onDateChange = (
    startDate,
    endDate,
    filterType,
    timePeriodVal,
    isDateChanged
  ) => {
    if (isDateChanged) {
      let newState = { ...filterState };
      let weekPostedDate = FILTERS.WEEK_POSTED_DATE.field;
      newState[weekPostedDate] = false;
      newState[`${weekPostedDate}Length`] = length;
      newState[`${weekPostedDate}Selected`] = [];
      setFilterState({ ...newState });
      onDateSelectionChange(startDate, endDate, filterType, timePeriodVal);
    }
  };

  /** function to hide filter section */
  // const renderHideShowButton = (text, toggleClassName, onClickName) => {
  //   return (
  //     <div
  //       className={`toggle-filters ${toggleClassName}`}
  //       onClick={onClickName}
  //     >
  //       <div className={`arrow ${isFilterOpen ? 'left' : 'top'}`} />
  //       <div className="content">{text}</div>
  //     </div>
  //   );
  // };

  /** function to set filter section state */
  // const openFilters = () => {
  //   updateFilterState(false);
  // };

  /** function to set filter section state */
  // const closeFilters = () => {
  //   updateFilterState(true);
  // };

  return (
    <>
      {/* {!isFilterOpen &&
        renderHideShowButton('Show Filters', 'floatLeft', closeFilters)} */}
      <Grid.Column
        width={3}
        className={`Kt_dashboard_body_left ${isFilterOpen ? 'show' : 'hide'}`}
      >
        {/* {isFilterOpen &&
          renderHideShowButton('Hide Filters', 'floatRight', openFilters)} */}
        <div className="filters_container">
          {!isBillRatePageOpen && renderDateContainer()}
          {filtersToShow?.showBusinessFilters ? renderBusinessFilters() : null}
          {filtersToShow?.showMemberFilters ? renderTeamMemberFilters() : null}
        </div>
      </Grid.Column>
    </>
  );
};

export default KTFilters;
