import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';

import { globalAdobeAnalytics } from 'config/analytics';
import UserList from 'components/UserManagement/UserList';
import EditUser from 'components/UserManagement/EditUser';
import AuthorizeHOC from 'components/common/authorizeHOC';
import UploadModal from 'components/common/UploadModal';
import Button from 'components/common/button';
import Icon from 'components/common/icon/Icon';
import CONFIG from 'config/configProps';
import { showUserManagementPage } from 'utils';
import { uploadUserList } from 'redux/actions/selfServiceActions';

import 'components/UserManagement/UserManagement.scss';

const NewUserManagement = props => {
  const { loggedInUserHighestRole, history, dispatch } = props;
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [reloadUserList, setReloadUserList] = useState('');
  const [editView, setEditView] = useState({
    enabled: false,
    userInfo: null,
  });

  const hasAccess = useCallback(() => {
    return showUserManagementPage(loggedInUserHighestRole);
  }, [loggedInUserHighestRole]);

  useEffect(() => {
    // default scrolling page to top
    window.scrollTo(0, 0);
    globalAdobeAnalytics('KTDashboard-Legacy-UserManagement');
  }, []);

  useEffect(() => {
    if (!hasAccess()) {
      history.push({
        pathname: CONFIG.KT_SCORECARD_CONST.HOME_URL,
      });
    }
  }, [hasAccess, history]);

  const openUploadModal = () => {
    setUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setUploadModalOpen(false);
  };

  const handleFileUpload = (formData, setUploadStatus) => {
    return dispatch(uploadUserList({ formData, setUploadStatus })).then(
      response => {
        setReloadUserList(new Date().valueOf());
        return response.data;
      }
    );
  };

  const toggleEditView = userInfo => {
    setEditView({ enabled: !editView.enabled, userInfo });
  };

  return (
    <div
      className={`tableau-container ${editView.enabled ? 'editMode' : ''}`}
      id="user-management"
    >
      <div className="user-management-container">
        {!editView.enabled && (
          <div className="user-management-container__instructions">
            <h2 className="user-management-container__instructions__title">
              User Management
              <Button variant="upload-btn" onClick={openUploadModal}>
                Upload File
              </Button>
            </h2>
            <ul className="user-management-container__instructions__items">
              <li>Validate &lsquo;ManagedBy&rsquo; alignment.</li>
              <li>
                Click on &lsquo;
                <Icon name="edit" />
                &rsquo; for updating the user details.
              </li>
              <li>
                Input missing data of team member(e.g., Practice Area,
                Topic/Segment, Sub-Topic/Segment aligments)
              </li>
              <li>
                After updating the details, you can &lsquo;Save as draft&rsquo;
                and you can re-visit later and edit the details if required or
                you can &lsquo;Activate&rsquo; the user.
              </li>
            </ul>
          </div>
        )}
        <div className="user-management-container__users">
          {editView.enabled ? (
            <EditUser
              userInfo={editView.userInfo}
              onClickGoBack={toggleEditView}
            />
          ) : (
            <UserList
              reloadUserList={reloadUserList}
              enableEditView={toggleEditView}
            />
          )}
        </div>
      </div>
      {uploadModalOpen && (
        <UploadModal
          open={uploadModalOpen}
          onModalClose={closeUploadModal}
          handleFileUpload={handleFileUpload}
        />
      )}
    </div>
  );
};

const mapStateToProps = function (reduxStore) {
  return {
    loggedInUserHighestRole: reduxStore.userRoleData.loggedInUserHighestRole,
  };
};

export default connect(mapStateToProps)(AuthorizeHOC(NewUserManagement));
