const paramString = '?:origin=card_share_link&:embed=n';
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const BILLABILITY_TABLEAU_URL = process.env.REACT_APP_BILLABILITY_TABLEAU_URL;
const RMS_TABLEAU_URL = process.env.REACT_APP_RMS_TABLEAU_URL;
const PULSE_TABLEAU_URL = process.env.REACT_APP_PULSE_TABLEAU_URL;
const FTE_TABLEAU_URL = process.env.REACT_APP_FTE_TABLEAU_URL;

export const BILLABILITY_METRIC = 'Billability';
export const WORKSPLIT_METRIC = 'Work Split';
export const ENGAGEMENT_METRIC = 'Engagement Depth';
export const CONTENT_CREATION_METRIC = 'Content Creation';
export const TEAM_SATISFACTION_METRIC = 'Team Satisfaction';
export const CT_SATISFACTION_METRIC = 'CT Satisfaction';
export const TEAM_SIZE_METRIC = 'Team Size';
export const OPTION_ALL = 'All';
export const allSector = {
  topicSectorId: -1,
  sector: OPTION_ALL,
  sharedSubSector: null,
};
export const allSubSector = {
  subSectorId: -1,
  subSector: OPTION_ALL,
  sharedSubSector: null,
};
export const SUPER_ADMIN_ROLE_ID = 1;
export const PA_ADMIN_ROLE_ID = 2;
export const MANAGER_ROLE_ID = 3;
export const MANAGER_VIEWER_ROLE_ID = 4;
export const PA_ADMIN_VIEWER_ROLE_ID = 5;
export const SUPER_ADMIN_VIEWER_ROLE_ID = 6;
export const GEO_MANAGER_ROLE_ID = 7;
export const GEO_TEAM_MEMBER_ROLE_ID = 8;
export const IMPERSONATOR_ROLE_ID = 9;
export const GEO_LEAD_ROLE_ID = 10;
export const BUSINESS_COMPARISON_ROLE_ID = 11;
export const TEAM_MEMBER_ROLE_ID = 9999;

export const SUPER_ADMIN_ROLE = 'Super Admin';
export const SUPER_ADMIN_VIEWER = 'Super Admin (Viewer)';
export const PA_ADMIN_ROLE = 'PA Admin';
export const PA_ADMIN_VIEWER_ROLE = 'PA Admin (Viewer)';
export const IMPERSONATOR_ROLE = 'Impersonator';
export const GEO_LEAD_ROLE = 'Geo Lead';
export const MANAGER_ROLE = 'Manager';
export const MANAGER_VIEWER_ROLE = 'Manager (Viewer)';
export const GEO_MANAGER_ROLE = 'Geo Manager';
export const TEAM_MEMBER_ROLE = 'Team Member';
export const GEO_TEAM_MEMBER_ROLE = 'Geo Team Member';
export const BUSINESS_COMPARISON_ROLE = 'Business Comparison';

export const SIZE_IN_FTE = 'SizeinFTE';
export const REQUEST_SATISFACTION = 'RequestSatisfaction';
export const KT_PULSE = 'KtPulseCheck';
export const CASE_BILLABILITY_ADMIN = 'CaseBillabilityAdmin';
export const CASE_BILLABILITY = 'CaseBillability';
export const CASE_BILLABILITY_SPLIT = 'HoursCharges';
export const BILLABILITY_TREND_BY_TEAM_MEMBER = 'BillabilityByTeamMember';
export const BILL_RATE_BY_TEAM_MEMBER = 'BillRatebyTeamMember';
export const HOME_URL = '/legacyDashboard';
export const TEAM_EDIT_MODE_PAGE_URI = '/legacyDashboard/teamEditMode';
export const EMPLOYEE_BILLABILITY = '/legacyDashboard/employeeBillability';
export const ADMIN_PAGE_URI = '/legacyDashboard/admin';
export const KT_ADMIN_PAGE = '/legacyDashboard/ktAdminPage';
export const BUSINESS_COMPARISON = '/legacyDashboard/businessComparison';
export const SELF_SERVICE_PAGE_URI = '/legacyDashboard/selfService';
export const MANAGE_NEW_USER_PAGE_URI = `${SELF_SERVICE_PAGE_URI}/manageNewUser`;
export const MANAGE_EXISTING_USER_PAGE_URI = `${SELF_SERVICE_PAGE_URI}/manageExistingUser`;
export const KT_TABLEAU_URL = '/legacyDashboard/detailedView/';
export const KT_TABLEAU_URL_SIZE_IN_FTE = `${KT_TABLEAU_URL}${SIZE_IN_FTE}`;
export const KT_TABLEAU_URL_REQUEST_SATISFACTION = `${KT_TABLEAU_URL}${REQUEST_SATISFACTION}`;
export const KT_TABLEAU_URL_KT_PULSE = `${KT_TABLEAU_URL}${KT_PULSE}`;
export const KT_TABLEAU_URL_CASE_BILLABILITY_ADMIN = `${KT_TABLEAU_URL}${CASE_BILLABILITY_ADMIN}`;
export const KT_TABLEAU_URL_CASE_BILLABILITY = `${KT_TABLEAU_URL}${CASE_BILLABILITY}`;
export const KT_TABLEAU_URL_CASE_BILLABILITY_SPLIT = `${KT_TABLEAU_URL}${CASE_BILLABILITY_SPLIT}`;
export const KT_TABLEAU_URL_BILLABILITY_TREND_BY_TEAM_MEMBER = `${KT_TABLEAU_URL}${BILLABILITY_TREND_BY_TEAM_MEMBER}`;
export const KT_TABLEAU_URL_BILL_RATE_BY_TEAM_MEMBER = `${KT_TABLEAU_URL}${BILL_RATE_BY_TEAM_MEMBER}`;
export const CD_BILLABILITY_URL = '/legacyDashboard/CDBillability';
export const CASE_ENGAGEMENT_DEPTH_URL = '/legacyDashboard/CaseEngagementDepth';

const PA_KT_AXIS = { label: 'PA KT Axis', value: 'PA KT Axis' };
const GEO_KT_AXIS = { label: 'Geo KT Axis', value: 'Geo KT Axis' };

const CONFIG = {
  ENV: process.env.REACT_APP_ENVIRONMENT,
  KT_SCORECARD_API_URL: `${BASE_URL}ktscorecard/ScorecardPortal/api/`,
  KT_BILLING_API_URL: `${BASE_URL}ktd-billing/billing/api/`,
  KT_CONTENT_API_URL: `${BASE_URL}ktd-knowledge-page/knowledge-page-service/api/`,
  KT_FILTERS_API_URL: `${BASE_URL}ktd-user/kd-user-service/api/filter/`,
  KT_USER_BASE_URL: `${BASE_URL}ktd-user/kd-user-service/api/`,
  X_API_KEY: process.env.REACT_APP_X_API_KEY,
  PEOPLE_X_API_KEY: process.env.REACT_APP_PEOPLE_X_API_KEY,
  PEOPLE_API_URL: process.env.REACT_APP_PEOPLE_API_URL,
  BCG_URL: {
    STAFF_DETAIL_URL: hrId =>
      `${process.env.REACT_APP_NAVIGATOR_URL}/Detail/staff_detail.asp?Staff_ID=${hrId}`,
  },
  OKTA: {
    ISSUER: process.env.REACT_APP_OKTA_URL,
    REDIRECT_URL: '/implicit/callback',
    CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID,
    BASE_URL: process.env.REACT_APP_OKTA_BASE_URL,
    ACCESS_TOKEN: process.env.REACT_APP_TOKEN,
  },
  APP_CONST: { UNAUTHORIZED: '/unauthorized' },
  DATE_TIME_FORMAT: {
    KT_SCORECARD_DISPLAY: 'MMM,YYYY',
    KT_SCORECARD: 'DD MMM YY',
    KT_SCORECARD_DATE_TIME: 'DD-MM-YYYY',
    SELF_SERVICE: 'MMM DD YYYY',
    KT_SCORECARD_API: 'YYYY-MM-DD',
  },
  DATE_TIME_FORMAT_DISPLAY: {
    MONTHLY: 'MMM YYYY',
    TTM: 'MMM YYYY',
    WEEKLY: 'MMM-DD,YYYY',
    YTD: 'MMM YYYY',
    CUSTOM: 'MMM-DD,YYYY',
    API_FORMAT: 'YYYY-MM-DD',
    API_FORMAT_ALL: 'MMM YY',
  },
  MONTH_SORT_NAMES: [
    0,
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  EXTERNAL_APPLICATION: {
    PEOPLE_API: 'peopleApi',
  },
  KT_SCORECARD_CONST: {
    HOME_URL,
    TEAM_EDIT_MODE_PAGE_URI,
    EMPLOYEE_BILLABILITY,
    ADMIN_PAGE_URI,
    KT_ADMIN_PAGE,
    KT_TABLEAU_URL,
    KT_TABLEAU_URL_SIZE_IN_FTE,
    KT_TABLEAU_URL_REQUEST_SATISFACTION,
    KT_TABLEAU_URL_KT_PULSE,
    KT_TABLEAU_URL_CASE_BILLABILITY_ADMIN,
    KT_TABLEAU_URL_CASE_BILLABILITY,
    KT_TABLEAU_URL_CASE_BILLABILITY_SPLIT,
    KT_TABLEAU_URL_BILLABILITY_TREND_BY_TEAM_MEMBER,
    KT_TABLEAU_URL_BILL_RATE_BY_TEAM_MEMBER,
    BUSINESS_COMPARISON,
    MANAGE_NEW_USER_PAGE_URI,
    MANAGE_EXISTING_USER_PAGE_URI,
    CD_BILLABILITY_URL,
    CASE_ENGAGEMENT_DEPTH_URL,
    DEFAULT_ERROR_MESSAGE:
      'Error occurred while saving the data. Please try again. If the error persists reach out to system admin at KnowledgeSystemsSupport@bcg.com.',
    DEFAULT_ERROR_MESSAGE_FOR_API_FAIL:
      'Some thing went wrong. Please try after sometime and if the error persists reach out to system admin at KnowledgeSystemsSupport@bcg.com.',
    KT_SCORECARD_REPORT_NAME: 'KT Scorecard',
    KT_DASHBOARD_REPORT_NAME: 'KT Dashboard',
    SUPER_ADMIN_GROUP_NAME: 'KBI KT Dashboard_SuperAdmin',
  },
  TABLEAU_URL: {
    /*case billability url */
    CASE_BILLABILITY: `${BILLABILITY_TABLEAU_URL}1_CasebillabilityPAAdmin${paramString}`,
    ADMIN_CASE_BILLABILITY: `${BILLABILITY_TABLEAU_URL}1_CasebillabilityPAAdmin_KTUser${paramString}`,
    MANAGER_CASE_BILLABILITY: `${BILLABILITY_TABLEAU_URL}2_CasebillabilityTeammanager${paramString}`,
    MEMBER_CASE_BILLABILITY: `${BILLABILITY_TABLEAU_URL}3_TeamMemberCaseBillability${paramString}`,
    GEO_PA_CASE_BILLABILITY: `${BILLABILITY_TABLEAU_URL}4_GEOwtPATeamMemberCaseBillability${paramString}`,
    GEO_MANAGER_CASE_BILLABILITY: `${BILLABILITY_TABLEAU_URL}4_GEOwtPATMCaseBillabilityGeoManager${paramString}`,
    GEO_LEAD_CASE_BILLABILITY: `${BILLABILITY_TABLEAU_URL}4_GEOwtPATMCaseBillabilityGeo${paramString}`,
    GEO_ADMIN_CASE_BILLABILITY: `${BILLABILITY_TABLEAU_URL}CasebillabilityPAandGEO${paramString}`,
    BILLABILITY_TREND_BY_TEAM_MEMBER: `${BILLABILITY_TABLEAU_URL}BillabilityTrendTeamMember${paramString}`,
    BILL_RATE_BY_TEAM_MEMBER: `${BILLABILITY_TABLEAU_URL}BillRatebyTeamMember${paramString}`,
    /*case billability split url */
    CASE_BILLABILITY_SPLIT: `${BILLABILITY_TABLEAU_URL}HoursChargesforAdminandmanager${paramString}`,
    MEMBER_CASE_BILLABILITY_SPLIT: `${BILLABILITY_TABLEAU_URL}TeamMemberHoursCharges${paramString}`,
    GEO_MEMBER_CASE_BILLABILITY_SPLIT: `${BILLABILITY_TABLEAU_URL}TeamMemberHoursChargesGEO${paramString}`,
    GEO_MANAGER_CASE_BILLABILITY_SPLIT: `${BILLABILITY_TABLEAU_URL}HoursChargesforAdminandmanagerGeo${paramString}`,
    /*RMS url */
    REQUEST_SATISFACTION: `${RMS_TABLEAU_URL}RequestSatisfaction${paramString}`,
    GEO_REQUEST_SATISFACTION: `${RMS_TABLEAU_URL}RequestSatisfactionGeo${paramString}`,
    GEO_PA_REQUEST_SATISFACTION: `${RMS_TABLEAU_URL}RequestSatisfactionPA-Geo${paramString}`,
    GEO_MANAGER_RMS: `${RMS_TABLEAU_URL}RequestSatisfactionGeoTA-TM${paramString}`,
    /*size in fte url */
    SIZE_IN_FTE: `${FTE_TABLEAU_URL}FTEDeepDivePA${paramString}`,
    GEO_MANAGER_SIZE_IN_FTE: `${FTE_TABLEAU_URL}FTEDeepDiveGeoTM${paramString}`,
    GEO_MEMBER_SIZE_IN_FTE: `${FTE_TABLEAU_URL}FTEDeepDiveGeoMember${paramString}`,
    /*kt pulse url */
    KT_PULSE: `${PULSE_TABLEAU_URL}PulseSurveyReportAdminandManager${paramString}`,
    KT_PULSE_MEMBER: `${PULSE_TABLEAU_URL}PulseSurveyReportTeamMember${paramString}`,
    GEO_MANAGER_KT_PULSE_MEMBER: `${PULSE_TABLEAU_URL}PulseSurveyReportAdminandManager2${paramString}`,
    GEO_KT_PULSE_MEMBER: `${PULSE_TABLEAU_URL}PulseSurveyReportAdminandManagerGEOPA${paramString}`,
    GEO_PA_KT_PULSE_MEMBER: `${PULSE_TABLEAU_URL}PulseSurveyReportAdminandManagerPAGEO${paramString}`,
    /*Business Comparison Url */
    BUSINESS_COMPARISON_URL: {
      [BILLABILITY_METRIC]: `${process.env.REACT_APP_BC_BILLABILITY_URL}${paramString}`,
      [WORKSPLIT_METRIC]: `${process.env.REACT_APP_BC_WORKSPLIT_URL}${paramString}`,
      [ENGAGEMENT_METRIC]: {
        [PA_KT_AXIS.value]: `${process.env.REACT_APP_BC_PA_KT_ENGAGEMENT_URL}${paramString}`,
        [GEO_KT_AXIS.value]: `${process.env.REACT_APP_BC_GEO_KT_ENGAGEMENT_URL}${paramString}`,
      },
      [CONTENT_CREATION_METRIC]: `${process.env.REACT_APP_BC_CONTENT_CREATION_URL}${paramString}`,
      [TEAM_SATISFACTION_METRIC]: `${process.env.REACT_APP_BC_TEAM_SATISFACTION_URL}${paramString}`,
      [CT_SATISFACTION_METRIC]: `${process.env.REACT_APP_BC_CT_SATISFACTION_URL}${paramString}`,
      [TEAM_SIZE_METRIC]: `${process.env.REACT_APP_BC_TEAM_SIZE_URL}${paramString}`,
    },
  },
  TABLEAU_WORKSHEET: {
    BILLABILITY_TREND_BY_TEAM_MEMBER: 'Billability Trend By Team Member',
    BILLABILITY_TREND_BY_TEAM_MEMBER_HEADER:
      'Billability Trend By Team Member Header',
    BILL_RATE_BY_TEAM_MEMBER: 'Current Bill Rate by Team Member',
  },
  CKA_DASHBOARD_URL:
    'https://tableauha.bcg.com/t/GSBFinance/views/CKABillabilityReport/LandingPage?%3AiframeSizedToWindow=true&%3Aembed=y&%3AshowAppBanner=false&%3Adisplay_count=no&%3AshowVizHome=no#1',
  DETAILED_VIEW_TOKEN: {
    SIZE_IN_FTE,
    REQUEST_SATISFACTION,
    KT_PULSE,
    CASE_BILLABILITY_ADMIN,
    CASE_BILLABILITY,
    CASE_BILLABILITY_SPLIT,
    BILLABILITY_TREND_BY_TEAM_MEMBER,
    BILL_RATE_BY_TEAM_MEMBER,
  },
  SECTION: ['TA', 'CF', OPTION_ALL],
  API_URLS: {
    EMP: 'emp/',
    EMP_PA: 'emp/pa',
    EMP_SECTOR: 'emp/sector',
    EMP_SUB_SECTOR: 'emp/subSector',
    KT_CARD_USERS: 'emp/users',
    GET_ROLES: 'admin/roles?practiceAreaId=-1',
    GET_USER_DETAILS: 'admin/userdetails',
    ADMIN: 'admin/',
    UPDATES: 'updates/',
    CASE_BILLABILITY: 'billability/',
    CASE_BILLABILITY_TEAM: 'billability/cases-split-data',
    CASE_BILLABILITY_TOTAL: '/billability/total-split-data',
    CASE_BILLABILITY_DOWNLOAD: '/billability/download',
    ANNOTATED_DASHBOARD: 'cards/annotated/dashboard',
    FTE_DASHBOARD: 'dashboard/ftesize',
    RMS_DATA: 'rms/reqest-statisfaction',
    PULSE_CHECK: 'pulse/check/team-satisfaction',
    SAVE_COLLECTION: 'userfilter/',
    BC_SAVE_COLLECTION: 'businessComparison/userfilter/',
    MOST_DOWNLOADS: 'knpage/most/downloaded',
    ICON_INFORMATION: 'tileinformation/',
    ALL_ICON_INFORMATION: 'tileinformation/all',
    USER_DETAILS: 'user/access/details',
    IMPERSONATOR_USER_LIST: 'emp/users?',
    GET_GEO_REGION: 'emp/geoRegion',
    CLEAR_CACHE: 'emp/clearCache',
    SELF_SERVICE_USER_LIST: 'self-service/user/all',
    SELF_SERVICE_UPLOAD_USER_LIST: 'self-service/user/upload',
    SELF_SERVICE_USER_DETAILS: 'self-service/user/details',
    SELF_SERVICE_USER_DETAILS_SAVE_AS_DRAFT: 'self-service/user/saveAsDraft',
    SELF_SERVICE_USER_DETAILS_SAVE_AND_ACTIVATE:
      'self-service/user/saveAndActivate',
    SELF_SERVICE_SEARCH_USER: 'self-service/user/search',
    SELF_SERVICE_UPDATE_USER_DETAILS: 'self-service/user/update',
    SELF_SERVICE_ADD_NEW_ALIGNMENT: 'self-service/user/addNewEntry',
    KT_CD_BILLING_API_URL: `billability/cdc-split-data`,
    KT_CASE_ENGAGEMENT_DEPTH_URL: `billability/total-engagement-depth`,
  },
  BUSINESS_COMPARISON_API_URLS: {
    PA_LIST: 'practicearea',
    SECTOR_LIST: 'sector',
    SUB_SECTOR_LIST: 'subsector',
    GEO_MARKET_LIST: 'georegion',
    MANAGED_BY: 'managedby',
    PATH_LIST: 'path',
    MEMBER_LIST: 'businessComp/users',
    COLLECTION_LIST: 'businessComparison/userfilter/',
  },
  MANAGED_BY: [
    { label: 'PA Managed', value: 'PA Managed' },
    { label: 'Co-Managed', value: 'PA & Geo Managed' },
    {
      label: 'Geo Managed w/PA Alignment',
      value: 'Geo Managed w/PA Alignment',
    },
  ],
  MANAGED_BY_FOR_GEO: [
    { label: 'Geo Managed', value: 'Geo Managed' },
    { label: 'Co-Managed', value: 'PA & Geo Managed' },
    {
      label: 'Geo Managed w/PA Alignment',
      value: 'Geo Managed w/PA Alignment',
    },
  ],
  MANAGED_BY_DEFAULT_VALUE: ['PA Managed', 'PA & Geo Managed'],
  MANAGED_BY_FOR_GEO_DEFAULT_VALUE: [
    'Geo Managed',
    'PA & Geo Managed',
    'Geo Managed w/PA Alignment',
  ],
  TIME_PERIOD_OPTIONS: ['YTD'],
  TIME_PERIOD_MONTH: [
    'Last 2 Months',
    'Last 3 Months',
    'Last 4 Months',
    'Last 5 Months',
    'Last 6 Months',
    'Last 7 Months',
    'Last 8 Months',
    'Last 9 Months',
    'Last 10 Months',
    'Last 11 Months',
    'Last 12 Months',
    'Last 13 Months',
    'Last 14 Months',
  ],
  ADMIN_ROLES: [
    SUPER_ADMIN_ROLE,
    PA_ADMIN_ROLE,
    PA_ADMIN_VIEWER_ROLE,
    SUPER_ADMIN_VIEWER,
    IMPERSONATOR_ROLE,
    GEO_LEAD_ROLE,
  ],
  PA_ADMIN_ROLES: [PA_ADMIN_ROLE, PA_ADMIN_VIEWER_ROLE],
  SUPER_ADMIN_ROLES: [SUPER_ADMIN_ROLE, SUPER_ADMIN_VIEWER],
  GEO_LEAD_ROLE: [GEO_LEAD_ROLE],
  MANAGER_ROLES: [MANAGER_ROLE, MANAGER_VIEWER_ROLE, GEO_MANAGER_ROLE],
  IMPERSONATOR: IMPERSONATOR_ROLE,
  GEO_LEAD: GEO_LEAD_ROLE,
  GEO_LEAD_ROLE_LIST: [GEO_LEAD_ROLE, GEO_MANAGER_ROLE],
  ROLES: {
    SUPER_ADMIN_ROLE,
    PA_ADMIN_ROLE,
    MANAGER_ROLE,
    MANAGER_VIEWER_ROLE,
    PA_ADMIN_VIEWER_ROLE,
    SUPER_ADMIN_VIEWER,
    GEO_MANAGER_ROLE,
    GEO_TEAM_MEMBER_ROLE,
    IMPERSONATOR_ROLE,
    GEO_LEAD_ROLE,
    BUSINESS_COMPARISON_ROLE,
    TEAM_MEMBER_ROLE,
  },
  ROLE_IDS: {
    SUPER_ADMIN_ROLE_ID,
    PA_ADMIN_ROLE_ID,
    MANAGER_ROLE_ID,
    MANAGER_VIEWER_ROLE_ID,
    PA_ADMIN_VIEWER_ROLE_ID,
    SUPER_ADMIN_VIEWER_ROLE_ID,
    GEO_MANAGER_ROLE_ID,
    GEO_TEAM_MEMBER_ROLE_ID,
    IMPERSONATOR_ROLE_ID,
    GEO_LEAD_ROLE_ID,
    BUSINESS_COMPARISON_ROLE_ID,
    TEAM_MEMBER_ROLE_ID,
  },
  PATH: [OPTION_ALL, 'TA', 'CF'],
  INFO_EDIT_HEADING: [
    'Area',
    'ManagedBy',
    'Member',
    'Case Billability',
    'Case Billability by Team Member',
    'Cases Supported',
    'KN Decks',
    'Other Content',
    'Top 10 - Most Downloaded',
    'KT Pulse Check',
    'CT Satisfaction with KT',
    'Team Size',
    '$M NCCs/KT FTE',
    'Impersonator',
  ],
  MAIL_URL:
    'mailto:KTDashboardSupport@bcg.com?subject=KT Dashboard help request',
  PA_NAME_ALIAS_MAP: {
    'Energy and Utilities': 'Energy',
    'Technology Advantage': 'Tech and Digital Advantage',
  },
  ALIAS_TO_PA_NAME_MAP: {
    Energy: 'Energy and Utilities',
    'Tech and Digital Advantage': 'Technology Advantage',
  },
  GEO_USER_TYPES: [
    'Geo Managed',
    'PA & Geo Managed',
    'Geo Managed w/PA Alignment',
  ],
  MANAGED_BY_ADMIN: [
    { label: 'NA', value: 'NA' },
    { label: 'PA Managed', value: 'PA Managed' },
    { label: 'Geo Managed', value: 'Geo Managed' },
    { label: 'Co-Managed', value: 'PA & Geo Managed' },
    {
      label: 'Geo Managed w/PA Alignment',
      value: 'Geo Managed w/PA Alignment',
    },
  ],
  DISPLAY_TEXT: {
    NO_DATA_IN_DROPDOWN: 'No more options to display',
  },
  METRIC_LIST: [
    { label: 'Billability', value: BILLABILITY_METRIC },
    { label: 'Work Split', value: WORKSPLIT_METRIC },
    { label: 'Engagement Depth', value: ENGAGEMENT_METRIC },
    { label: 'Content Creation', value: CONTENT_CREATION_METRIC },
    { label: 'Team Satisfaction', value: TEAM_SATISFACTION_METRIC },
    { label: 'CT Satisfaction', value: CT_SATISFACTION_METRIC },
    { label: 'Team Size', value: TEAM_SIZE_METRIC },
  ],
  HIERARCHY_LIST: [PA_KT_AXIS, GEO_KT_AXIS],
  ALLOWED_ROLE_FOR_BUSINESS_COMPARISON: {
    [PA_ADMIN_ROLE_ID]: false,
    [PA_ADMIN_VIEWER_ROLE_ID]: false,
    [GEO_LEAD_ROLE_ID]: false,
  },
  NOT_ALLOWED_ROLES: {
    [IMPERSONATOR_ROLE_ID]: true,
    [TEAM_MEMBER_ROLE_ID]: true,
    [GEO_TEAM_MEMBER_ROLE_ID]: true,
  },
  GEO_ROLE_IDS: [
    GEO_MANAGER_ROLE_ID,
    GEO_TEAM_MEMBER_ROLE_ID,
    GEO_LEAD_ROLE_ID,
  ],
  ALL_PA_ROLE_IDS: {
    [SUPER_ADMIN_ROLE_ID]: true,
    [SUPER_ADMIN_VIEWER_ROLE_ID]: true,
    [GEO_LEAD_ROLE_ID]: true,
  },
  BREADCRUMBS: {
    [HOME_URL]: [
      {
        pageHeading: 'Home',
        pageUrl: HOME_URL,
      },
    ],
    [BUSINESS_COMPARISON]: [
      {
        pageHeading: 'Home',
        pageUrl: HOME_URL,
      },
      {
        pageHeading: 'Business Comparison',
        pageUrl: BUSINESS_COMPARISON,
      },
    ],
    [KT_TABLEAU_URL_CASE_BILLABILITY]: [
      {
        pageHeading: 'Home',
        pageUrl: HOME_URL,
      },
      {
        pageHeading: 'Billing | Case Billability',
        pageUrl: KT_TABLEAU_URL_CASE_BILLABILITY,
      },
    ],
    [KT_TABLEAU_URL_CASE_BILLABILITY_ADMIN]: [
      {
        pageHeading: 'Home',
        pageUrl: HOME_URL,
      },
      {
        pageHeading: 'Billing | Case Billability',
        pageUrl: KT_TABLEAU_URL_CASE_BILLABILITY_ADMIN,
      },
    ],
    [KT_TABLEAU_URL_CASE_BILLABILITY_SPLIT]: [
      {
        pageHeading: 'Home',
        pageUrl: HOME_URL,
      },
      {
        pageHeading: 'Billing | Hours/Charges',
        pageUrl: KT_TABLEAU_URL_CASE_BILLABILITY_SPLIT,
      },
    ],
    [KT_TABLEAU_URL_SIZE_IN_FTE]: [
      {
        pageHeading: 'Home',
        pageUrl: HOME_URL,
      },
      { pageHeading: 'Team | Team Size', pageUrl: KT_TABLEAU_URL_SIZE_IN_FTE },
    ],
    [KT_TABLEAU_URL_REQUEST_SATISFACTION]: [
      {
        pageHeading: 'Home',
        pageUrl: HOME_URL,
      },
      {
        pageHeading: 'Team | Request Satisfaction',
        pageUrl: KT_TABLEAU_URL_REQUEST_SATISFACTION,
      },
    ],
    [KT_TABLEAU_URL_KT_PULSE]: [
      {
        pageHeading: 'Home',
        pageUrl: HOME_URL,
      },
      {
        pageHeading: 'Team | KT Pulse Check',
        pageUrl: KT_TABLEAU_URL_KT_PULSE,
      },
    ],
    [EMPLOYEE_BILLABILITY]: [
      {
        pageHeading: 'Home',
        pageUrl: HOME_URL,
      },
      {
        pageHeading: 'Billing | Case Billability',
        pageUrl: EMPLOYEE_BILLABILITY,
      },
    ],
    [MANAGE_NEW_USER_PAGE_URI]: [
      {
        pageHeading: 'Home',
        pageUrl: HOME_URL,
      },
      {
        pageHeading: 'Self Service',
        pageUrl: '',
      },
      {
        pageHeading: 'User Management',
        pageUrl: MANAGE_NEW_USER_PAGE_URI,
      },
    ],
    [MANAGE_EXISTING_USER_PAGE_URI]: [
      {
        pageHeading: 'Home',
        pageUrl: HOME_URL,
      },
      {
        pageHeading: 'Self Service',
        pageUrl: '',
      },
      {
        pageHeading: 'User Management',
        pageUrl: MANAGE_NEW_USER_PAGE_URI,
      },
    ],
  },
  USER_LIST_COLUMNS: [
    {
      title: 'Name',
      dataField: 'employeeName',
    },
    {
      title: 'Team Manager',
      dataField: 'teamManager',
    },
    {
      title: 'Managed By',
      dataField: 'managedBy',
    },
    {
      title: 'Geo Market',
      dataField: 'geoRegion',
    },
    {
      title: 'Practice Area',
      dataField: 'paTeam',
      renderColumn: data => CONFIG.PA_NAME_ALIAS_MAP[data] || data,
    },
    {
      title: 'Topic/Segment',
      dataField: 'sector',
    },
    {
      title: 'Sub-Topic/Segment',
      dataField: 'subSector',
    },
    {
      title: 'Path',
      dataField: 'slotting',
    },
  ],
  MAX_FILE_SIZE_IN_BYTES: 2097152,
  ALLOWED_FILE_TYPES_FOR_UPLOAD: {
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
    ],
  },
  CASE_BILLABILITY_COLORS: {
    Case: '#29BA74',
    'Case w/o #': '#D4DF33',
    BD: '#30C1D7',
    Investment: '#741E92',
    'Innovation Fund': '#A1E26F',
    Other: '#008AC2',
    'Client Investment': '#197A56',
  },
  CD_BILLABILITY_COLORS: {
    Case: '#3BC34E',
    'Case w/o #': '#AEC33B',
    Investment: '#5B3A87',
    'Innovation Fund': '#A1E26F',
  },
  CASE_ENGAGEMENT_DEPTH_ALIAS: {
    lessThan4: '<4 h',
    between4and20: '4-20 h',
    between20and40: '20-40 h',
    between40and80: '40-80 h',
    greaterThan80: '>80 h',
  },
  CASE_ENGAGEMENT_DEPTH_COLORS: {
    lessThan4: '#29BA74',
    between4and20: '#D4DF33',
    between20and40: '#30C1D7',
    between40and80: '#741E92',
    greaterThan80: '#A1E26F',
  },
  PROTOTYPE_URLS: {
    HOME: '/',
    BILLING: '/billing',
    TIMESHEET: '/timesheet',
    CHARGES_HOURS: '/chargesHours',
    SURVEYS: '/surveys',
    HELP: '/help',
    verticaldd: '/verticaldd',
  },
  PROTOTYPE_HEADING: [
    { name: 'Billability', url: '/billing' },
    { name: 'Timesheets', url: '/timesheet' },
    { name: 'Charges/Hours', url: '/chargesHours' },
    // { name: 'Surveys', url: '/surveys' },
  ],
  PROTOTYPE_FILTERS: {
    START_DATE: {
      text: 'Start Date',
      field: 'startDate',
    },
    END_DATE: {
      text: 'End Date',
      field: 'endDate',
    },
    NAME: {
      text: 'Name',
      field: 'selectedUserId',
    },
    PATH: {
      text: 'Path',
      field: 'path',
    },
    STATUS: {
      text: 'Status',
      field: 'userStatus',
    },
    JOB_TITLE: {
      text: 'Job Title',
      field: 'jobTitle',
    },
    LOCATION: {
      text: 'Location',
      field: 'homeOffice',
    },
    MANAGEMENT_MODEL: {
      text: 'Management Model',
      field: 'managementModel',
    },
    GEO_BUSINESS: {
      text: 'Geo Business',
      field: 'geoRegion',
    },
    PRACTICE_AREA: {
      text: 'Practice Area',
      field: 'practiceArea',
    },
    WEEK_POSTED_DATE: {
      text: 'Months',
      field: 'weekPostedDates',
    },
  },
  FILTERS_PATH: [
    { label: 'Topic Activation', value: 'Topic Activation' },
    { label: 'Client Focus', value: 'Client Focus' },
  ],
  MANAGEMENT_MODEL: ['PA Managed', 'Geo Managed', 'Co Managed'],
  STATUS: ['Active', 'LOA', 'Inactive'],
  FILTER_MAP: 'availableFilterFieldMap',
  BILLING_TABS: ['Business', 'Team Member'],
  CHARGES_HOURS_TABS: ['Detail View', 'Bill Rate'],
  PROTOTYPE_BILLABILITY_COLORS: {
    Case: '#21BF61',
    'Case w/o #': '#0A477D',
    BD: '#76B9F4',
    Investment: '#A8F0B8',
    'Innovation Fund': '#0977D7',
    'Other/Admin': '#D4D4D4',
  },
  PROTOTYPE_BILLABILITY_LEGENDS: {
    caseBillability: 'Case',
    clientWithout: 'Case w/o #',
    bd: 'BD',
    investment: 'Investment',
    innovationFund: 'Innovation Fund',
    other: 'Other/Admin',
  },
  PROTOTYPE_BILLABILITY_LEGENDS_API_KEY: {
    caseBillability: '#21BF61',
    clientWithout: '#0A477D',
    bd: '#76B9F4',
    investment: '#A8F0B8',
    innovationFund: '#0977D7',
    other: '#D4D4D4',
  },
  PROTOTYPE_BILLABILITY_SORT_BY_OPTIONS: ['Expand All', 'Collapse All'],
  PROTOTYPE_CHARGES_DEFAULT_SORT_OPTIONS: ['', 'Bill Rate (USD)'],
  PROTOTYPE_BILLABILITY_COLUMNS: {
    memberBillability: {
      Name: 'string',
      'Case Chgs ($K)': 'number',
      'Theo Cap Chgs ($K)': 'number',
      'Case Billability': 'number',
      'CD Billability': 'number',
    },
    businessBillability: {
      Business: 'string',
      'Case Chgs ($K)': 'number',
      'Theo Cap Chgs ($K)': 'number',
      'Case Billability': 'number',
    },
  },
  PROTOTYPE_CHARGES_HOURS_COLUMNS: {
    detailedView: {
      Name: 'string',
      'Utilization Category': 'string',
      'Project Type': 'string',
      'Client Name': 'string',
      'Project Name': 'number',
      'Charges ($)': 'number',
      Hours: 'number',
      'Non-Converted Hours': 'number',
      'Theo Cap Chgs ($)': 'number',
    },
    billRate: {
      Name: 'string',
      'Job Title': 'string',
      'Home Office': 'string',
      'Bill Rate (Local)': 'number',
      'Bill Rate (USD)': 'number',
    },
  },
  PROTOTYPE_TIME_PERIOD_OPTIONS: ['YTD', 'TTM', 'CUSTOM'],
  BILLABILITY_TOTAL_KEYS: [
    'caseBillability',
    'bd',
    'innovationFund',
    'clientWithout',
    'investment',
    'other',
  ],
  PROTOTYPE_API_URLS: {
    LAST_UPDATED_DATE: 'billability/latest-week-posted-date',
    MEMBER_BILLABILITY: 'billability/work-split/employee',
    BUSINESS_BILLABILITY: 'billability/work-split/business',
    PRACTICE_AREA: 'practiceareas',
    GEO_LOCATION: 'geo-region',
    JOB_TITLE: 'jobTitle',
    LOCATION: 'system',
    TEAM_MEMBER: 'v2/users',
    CHARGES_HOURS: 'billability/detail-view',
    BILL_RATE: 'billability/bill-rate',
    TIMESHEET: 'billability/timesheet',
    TIMESHEET_DOWNLOAD_URL: 'billability/timesheet/download',
    TOP_DOWNLOADS: 'knpage/most/downloaded',
    ENGAGEMENT_DEPTH: 'billability/engagement-depth',
    TOP_CLIENTS: 'billability/top-clients',
    BILLABILITY_TRENDS: 'billability/work-split/monthly-trend',
    KT_PULSE_CHECK: 'pulse/check/monthly-trend',
    TEAM_SIZE: 'dashboard/team-size',
    TIMESHEET_SUMMARY: 'billability/timesheet-summary',
    KT_BILLABILITY: 'billability/billability-benchmark',
    HOURLY_BILL_RATE: 'billability/hourly-bill-rate',
    CD_BILLABILITY_URL: 'billability/work-split/cd-billability-summary',
    CHARGES_HOURS_TOTAL: 'billability/detail-view-charges-total',
    TOP_PROJECTS: 'billability/top-projects',
    IMPERSONATED_USER_DETAILS: 'user/role',
    RMS_SATISFACTION: 'rms/rms-survey',
    DEFAULT_USER_FILTERS: 'user-role/details',
  },
  BILLABILITY_REPORT_NAMES: [
    'Billability by Business',
    'Billability by Team Member',
  ],
  BILLABILITY_DOWNLOAD_URL: [
    '/billability/work-split/business/download',
    '/billability/work-split/employee/download',
  ],
  CHARGES_REPORT_NAMES: ['Charges_Hours', 'Bill Rate'],
  CHARGES_DOWNLOAD_URL: [
    '/billability/detail-view/download',
    '/billability/bill-rate/download',
  ],
  TIMESHEET_DOWNLOAD_REPORT_NAME: 'Timesheet',
  BILLABILITY_TOOLTIP_TEXT: [
    'The billability of a business considers the charges of team members while reporting to that specific business during the selected time period.',
    'The billability of a team member considers their entire work history during the selected period, regardless of the business they previously reported to.',
  ],
  TIMESHEET_TOOLTIP_TEXT:
    'Please note that data is updated every Tuesday. In case of late submissions, hours will be reported as zero. If you have any questions, please reach out to our support team at KTDashboardSupport@bcg.com',
  CHARGES_HOURS_TOOLTIP_TEXT: [
    'Please note that data is updated every Tuesday. If you have any questions, please reach out to our support team at KTDashboardSupport@bcg.com',
    'Please note that data is updated every Tuesday, displaying the most recent bill rates. If you have any questions, please reach out to our support team at KTDashboardSupport@bcg.com',
  ],
  SORTING_TOOLTIP_TEXT: {
    numberAcc: 'Sort Low to High',
    numberDesc: 'Sort High to Low',
    StringAcc: 'Sort A to Z',
    StringDesc: 'Sort Z to A',
  },
  KT_PULSE_CHECK_LEGENDS: {
    countVerySatisfied: 'Very Satisfied',
    countSatisfied: 'Satisfied',
    countNeutral: 'Neutral',
    countDissatisfied: 'Dissatisfied',
    countVeryDissatisfied: 'Very Dissatisfied',
  },
  KT_PULSE_CHECK_COLORS: {
    countVerySatisfied: '#8BC980',
    countSatisfied: '#B6DDAD',
    countNeutral: '#E0E0E0',
    countDissatisfied: '#F7CBCF',
    countVeryDissatisfied: '#F5999D',
  },
  KT_PULSE_CHECK_QUESTIONS: {
    'Overall Satisfaction': 'Overall Satisfaction at Work',
    'Doing interesting work & learning': 'Doing Interesting Work & Learning',
    'Have impact on business': 'Have Impact on Business',
    'Workload is sustainable': 'Workload is Sustainable',
    'Experience good team camaraderie': 'Experience Good Team Camaraderie',
    'Acknowledgement from CTs/MDPs':
      'CTs and MDPs acknowledge the value KT brings and utilizes my skills and talents effectively',
    'Supported by my line manager': 'Supported by My Line manager',
  },
  DEFAULT_KT_PULSE_CHECK_QUESTIONS: 'Overall Satisfaction at Work',
  CASE_ENGAGEMENT_DEPTH_LEGENDS: {
    lessThan4: '<4 hrs',
    between4and20: '4-20 hrs',
    between20and40: '20-40 hrs',
    between40and80: '40-80 hrs',
    greaterThan80: '>80 hrs',
  },
  CASE_ENGAGEMENT_DEPTH_CASE_CHAREGS_LEGENDS: {
    lessThan4: 'caseChgsLessThan4',
    between4and20: 'caseChgsBetween4and20',
    between20and40: 'caseChgsBetween20and40',
    between40and80: 'caseChgsBetween40and80',
    greaterThan80: 'caseChgsGreaterThan80',
  },
  USER_ROLES: ['ktd_analyst', 'ktd_manager', 'ktd_leader', 'ktd_super_admin'],
  SUPER_ADMIN_ROLE_NEW: 'ktd_super_admin',
  LEADER_ROLE: 'ktd_leader',
  MANAGER_ROLE_NEW: 'ktd_manager',
  ANALYST_ROLE: 'ktd_analyst',
  PAGE_TABS: {
    ktd_analyst: ['My Dashboard', 'Charges/Hours'],
    ktd_manager: ['Business Analytics'],
    ktd_leader: ['Business Analytics'],
    ktd_super_admin: ['Business View'],
  },
  PROTOTYPE_TEAM_SIZE_LEGENDS_API_KEY: {
    TopicActivation: '#CBE1EE',
    ClientFocus: '#1F465F',
  },
  PROTOTYPE_TEAM_SIZE_LEGENDS: {
    TopicActivation: 'Topic Activation',
    ClientFocus: 'Client Focus',
  },
  KT_BILLABILITY_KEYS: {
    'ALL KT': 'Overall_KT',
    'Topic Activation': 'TA_Billability',
    'Client Focus': 'CF_Billability',
    'PA KT': 'Overall_PA_KT',
    'FPA KT': 'FPA_Billability',
    'IPA KT': 'IPA_Billability',
    'Geo KT': 'Overall_GEO_KT',
  },
  KT_BILLABILITY_QUESTIONS: {
    Case: 'CASE_CHARGES',
    'Client w/o #': 'CASE_WITHOUT_BILLABILITY',
    BD: 'BUSINESS_DEVELOPMENT_BILLABILLITY',
    Investment: 'INVESTMENT_BILLABILLITY',
    'Innovation Fund': 'INNOVATION_FUND_BILLABILLITY',
    'Other/Admin': 'OTHER_BILLABILLITY',
  },
  DEFAULT_BILLABILITY_QUESTIONS: 'CASE_CHARGES',
  CD_BILLABILITY_LEGENDS: {
    Case: 'caseBillability',
    'Client w/o #': 'clientWithout',
    'Client Investment': 'investment',
    'Innovation Fund': 'innovationFund',
  },
};
export default CONFIG;
