import * as React from 'react';
import Checkbox from '../CheckboxLabel';
import Icon from 'components/common/icon/Icon';

const Option = ({
  option,
  checked,
  partialChecked,
  handleOptionChange,
  expanded,
  canExpand,
  handleExpandClick,
}) => {
  const { label } = option;
  return (
    <div className="vertical-option" onClick={handleExpandClick}>
      <Checkbox
        labelTxt={label}
        handleChange={handleOptionChange}
        checked={checked}
        partialChecked={partialChecked}
      />
      {canExpand ? (
        <Icon name={`angle ${expanded ? 'up' : 'down'}`} />
      ) : (
        <div className="arrow-container" />
      )}
    </div>
  );
};

export default Option;
