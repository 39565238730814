import React, { useEffect, useState } from 'react';
import CONFIG from 'config/configProps';
import './styles/index.scss';
import OptionNode from './OptionNode';
import { isEmpty, isEqual } from 'utils/common';
import {
  backwardSelectionChange,
  forwardSelectionChange,
  getLengthOfTreeData,
  getLenghtOfSelectedValue,
} from 'utils/dd';
import Checkbox from './CheckboxLabel';
import { isEmptyOrNull, isSuperAdminRoleNew } from 'utils';
import { onFiltersDropDownOpen, onFiltersInteractions } from 'config/analytics';

const ReactTreeDropdown = props => {
  const {
    dropdownOptions,
    filterName,
    clickedValues,
    userRole,
    defaultUserFilters,
  } = props;
  const isSuperAdmin = isSuperAdminRoleNew(userRole);
  const dropdownOptionsLength = getLengthOfTreeData(dropdownOptions);
  const [selectedValues, setSelectedValues] = useState(
    props?.selectedIds || []
  );
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedOnlyValues, setSelectedOnlyValues] = useState(clickedValues);

  useEffect(() => {
    onFiltersDropDownOpen(`${props.filterText} Filter Opened`);
  }, []);

  useEffect(() => {
    const length = dropdownOptionsLength + 1;
    if (isEqual(Object.keys(selectedValues).length, length)) {
      let isAllSelected = true;
      Object.keys(selectedValues).forEach(value => {
        if (!selectedValues[value].checked) {
          isAllSelected = false;
        }
      });
      setIsAllSelected(isAllSelected);
    }
  }, [setIsAllSelected]);

  const handleAllClick = ({ target }) => {
    const checked = target.checked;
    setIsAllSelected(checked);
    if (checked) {
      let tmpCheckOptionsState = forwardSelectionChange(
        true,
        Object.assign({}, selectedValues),
        'All',
        dropdownOptions
      );
      setSelectedValues(tmpCheckOptionsState);
      handleOptionChange(checked, 'All', dropdownOptions);
    } else {
      setSelectedValues({});
      setSelectedOnlyValues({});
    }
  };

  const handleOptionChange = (checked, option, options) => {
    let index;
    const { paAll, topicSectorAll, subSectorAll, allWeekPostedDates } =
      selectedOnlyValues;
    let pa = !isEmptyOrNull(paAll) ? paAll : [];
    let sector = !isEmptyOrNull(topicSectorAll) ? topicSectorAll : [];
    let subSector = !isEmptyOrNull(subSectorAll) ? subSectorAll : [];
    let weekPostedDates = !isEmptyOrNull(allWeekPostedDates)
      ? allWeekPostedDates
      : [];

    switch (option.type) {
      case 'PRACTICE_AREA':
        index = pa.indexOf(option.id);
        checked ? pa.push(option.id) : pa.splice(index, 1);
        break;
      case 'SECTOR':
        index = sector.indexOf(option.id);
        checked ? sector.push(option.id) : sector.splice(index, 1);
        break;
      case 'SUB_SECTOR':
        index = subSector.indexOf(option.id);
        checked ? subSector.push(option.id) : subSector.splice(index, 1);
        break;
      case 'allWeekPostedDates':
      case 'weekPostedDates':
        index = weekPostedDates.indexOf(option.id);
        checked
          ? weekPostedDates.push(option.id)
          : weekPostedDates.splice(index, 1);
        break;
      default:
        break;
    }
    if (!checked) {
      setIsAllSelected(false);
    }
    let tmpCheckOptionsState = forwardSelectionChange(
      checked,
      Object.assign({}, selectedValues),
      option,
      options
    );
    tmpCheckOptionsState = backwardSelectionChange(
      checked,
      tmpCheckOptionsState,
      option,
      options
    );
    setSelectedValues(tmpCheckOptionsState);
    setSelectedOnlyValues(getAllSelectedValues(tmpCheckOptionsState));
  };

  const getAllSelectedValues = selectedValues => {
    let paAll = [],
      topicSectorAll = [],
      subSectorAll = [],
      allWeekPostedDates = [];
    Object.keys(selectedValues).map(key => {
      const value = selectedValues[key];
      if (value.checked) {
        switch (value.type) {
          case 'PRACTICE_AREA':
            if (
              (!isSuperAdmin &&
                defaultUserFilters?.paAll?.includes(value.id)) ||
              isSuperAdmin
            )
              paAll.push(value.id);
            break;
          case 'SECTOR':
            if (
              (!isSuperAdmin &&
                defaultUserFilters?.topicSectorAll?.includes(value.id)) ||
              isSuperAdmin
            )
              topicSectorAll.push(value.id);
            break;
          case 'SUB_SECTOR':
            subSectorAll.push(value.id);
            break;
          case 'allWeekPostedDates':
            allWeekPostedDates.push(value.id);
            break;
          default:
            break;
        }
      }
    });
    return {
      paAll,
      topicSectorAll,
      subSectorAll,
      allWeekPostedDates,
    };
  };

  const renderOption = (option, index) => {
    return (
      <OptionNode
        checkedOptions={selectedValues}
        key={`verticalParent${index}`}
        option={option}
        parent={undefined}
        handleOptionChange={handleOptionChange}
      />
    );
  };

  const applyFilter = () => {
    let weekPostedDates = [],
      homeOffice = [],
      paIds = [],
      topicSectorIds = [],
      subSectorIds = [];
    for (let [key, value] of Object.entries(selectedValues)) {
      if (value.checked || value.partialChecked) {
        switch (value.type) {
          case CONFIG.PROTOTYPE_FILTERS.LOCATION.field:
            homeOffice.push(value.id);
            break;
          case CONFIG.PROTOTYPE_FILTERS.WEEK_POSTED_DATE.field:
            weekPostedDates.push(value.id);
            break;
          case 'PRACTICE_AREA':
            paIds.push(value.id);
            break;
          case 'SECTOR':
            topicSectorIds.push(value.id);
            break;
          case 'SUB_SECTOR':
            subSectorIds.push(value.id);
            break;
          default:
            break;
        }
      }
    }
    const finalSelectedNodes = {
      homeOffice,
      weekPostedDates,
      paIds,
      topicSectorIds,
      subSectorIds,
      paAll: selectedOnlyValues?.paAll,
      topicSectorAll: selectedOnlyValues?.topicSectorAll,
      subSectorAll: selectedOnlyValues?.subSectorAll,
      allWeekPostedDates: selectedOnlyValues?.allWeekPostedDates,
    };
    onFiltersInteractions(
      props.filterText,
      `${props.filterText} Filter applied`
    );
    props.applyFilter(
      filterName,
      finalSelectedNodes,
      getLenghtOfSelectedValue(selectedValues),
      '',
      true,
      selectedValues
    );
  };

  const clearFilter = event => {
    setSelectedValues([]);
    setSelectedOnlyValues({});
    if (event) {
      event.stopPropagation();
    }
    props.applyFilter(filterName, {}, 0, '', true, []);
  };

  const disabled = isEmpty(selectedValues);
  return (
    <div
      className={`treeSelectContainer ${
        props.className ? props.className : ''
      }`}
    >
      <div>
        <div className="react-tree-menu vertical-wrapper">
          <div className="vertical-dropdown">
            {dropdownOptions.length > 1 && (
              <div className="vertical-option">
                <Checkbox
                  labelTxt={'All'}
                  handleChange={handleAllClick}
                  checked={isAllSelected}
                />
              </div>
            )}
            {dropdownOptions.map(renderOption)}
          </div>
        </div>
      </div>
      <div className="button-footer">
        <div className="clear" onClick={() => clearFilter()}>
          Clear
        </div>
        <div
          className={`apply ${disabled ? 'disabled' : ''}`}
          onClick={e => {
            e.nativeEvent.stopImmediatePropagation();
            applyFilter();
          }}
        >
          Apply
        </div>
      </div>
    </div>
  );
};

export default ReactTreeDropdown;
